import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export interface AppState {
  isSync: boolean;
  lastSync: string;
  lang: string;
  currency: string;
  error: { type: string; message: string };
}

const initialState: AppState = {
  isSync: false,
  lastSync: "0000-00-00",
  lang: "fr",
  currency: "euro",
  error: {
    type: "",
    message: "",
  },
};

export const AppSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    doneSync: (state, action: PayloadAction<any>) => {
      state.isSync = true;
      state.lastSync = new Date().toString();
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = {
        type: action.payload?.type,
        message: action.payload?.type,
      };
    },
  },
});

export const { doneSync, setError } = AppSlice.actions;

export const isSync = (state: RootState) => state.app.isSync;
export const getError = (state: RootState) => state.app.error;
export const getAppSettings = (state: RootState) => state.app;

export default AppSlice.reducer;
