import {defaultReturnObject, ApiReturnDatas} from "Types/Api";

import sessionsApiObject from "Api/Models/Sessions";
const sessionsApi = new sessionsApiObject();

export const getSessions = (datas: any) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		datas.limit = datas.limit ? datas.limit : 40;

		sessionsApi.getList(datas).then(
			(result: any) => {
				returnDatas.status = "success";
				returnDatas.message = "request_success";
				returnDatas.data = result;
				resolve(returnDatas);
			},
			(error) => {
				console.log(error);
				returnDatas.message = "request_failure";
				reject(returnDatas);
			}
		);
	});
};

export const getSession = (id: number) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		sessionsApi.getOne(id).then(
			(result: any) => {
				returnDatas.status = "success";
				returnDatas.message = "request_success";
				returnDatas.data = result;
				resolve(returnDatas);
			},
			(error) => {
				console.log(error);
				returnDatas.message = "request_failure";
				reject(returnDatas);
			}
		);
	});
};

export const postSession = (datas: any) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		sessionsApi.post(datas).then(
			(result: any) => {
				returnDatas.status = "success";
				returnDatas.message = "request_success";
				returnDatas.data = result;
				resolve(returnDatas);
			},
			(error) => {
				console.log(error);
				returnDatas.message = "request_failure";
				reject(returnDatas);
			}
		);
	});
};

export const postImport = (datas: any) => {
	return new Promise((resolve, reject) => {
		sessionsApi.postImport(datas).then(
			(result: any) => {
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
};

export const deleteSession = (id: number) => {
	return new Promise((resolve, reject) => {
		sessionsApi.delete(id).then(
			(result: any) => {
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
};
