const MODE = "prod"; // dev, prod,local

const config = {
	/** Google API */
	googleApi: "AIzaSyB8qhtIGUbu9IjoloWV0yJRBDwO8BfJCLc",
	/** API URL */

	apiUrl:
		MODE === "dev"
			? "http://apigrignoux.brainmade.io/"
			: MODE === "local"
			? "http://api.grignoux.local/"
			: "https://api.grignoux.be/",
};

export default config;
