import React from "react";

import {createBrowserRouter, RouterProvider} from "react-router-dom";

/** Pages */
import Lost from "./Components/Pages/404";
import Page from "./Components/Pages/Index";
import Login from "./Components/Pages/Login";
import Show from "./Components/Pages/Screens/Show";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Page navigation={undefined} />,
		errorElement: <Lost />,
	},
	{
		path: "/:page",
		element: <Page navigation={undefined} />,
		errorElement: <Lost />,
	},
	{
		path: "/:page/:id",
		element: <Page navigation={undefined} />,
		errorElement: <Lost />,
	},
	{
		path: "/:page/:id/:tab",
		element: <Page navigation={undefined} />,
		errorElement: <Lost />,
	},
	{
		path: "/:page/:id/:tab/:param",
		element: <Page navigation={undefined} />,
		errorElement: <Lost />,
	},
	{
		path: "/login",
		element: <Login />,
		errorElement: <Lost />,
	},
	{
		path: "/show",
		element: <Show />,
		errorElement: <Lost />,
	},

	{
		path: "/show/:id",
		element: <Show />,
		errorElement: <Lost />,
	},

	{
		path: "/notfound",
		element: <Lost />,
		errorElement: <Lost />,
	},
]);

function App() {
	return <RouterProvider router={router} />;
}

export default App;
