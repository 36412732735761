import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { getError, setError } from "Redux/Reducers/App/AppSlice";

const ErrorNotification = (props: {
  position?: { vertical: string; horizontal: string };
}) => {
  const { position } = props;

  const [open, setOpen] = useState(false);
  const error = useAppSelector(getError);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  /*
  const vertical = position?.vertical ? position.vertical : "bottom";
  const horizontal = position?.horizontal ? position.horizontal : "right";
*/

  const vertical = "bottom";
  const horizontal = "right";

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch(setError({ type: "", message: "" }));
  };

  useEffect(() => {
    if (error.type !== "") {
      setOpen(true);
    } else {
      setOpen(false);
    }

    if (error.type === "wrong_credentials") {
      //navigate("/login");
    }
  }, [error, navigate]);

  if (error.type !== undefined && error.type !== "wrong_credentials") {
    return (
      <Snackbar
        className="errroNotification"
        open={open}
        autoHideDuration={600}
        message={"error"}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert variant="filled" severity="error" onClose={() => setOpen(false)}>
          {error.message ? error.message : t("ERRORS.default")}
        </Alert>
      </Snackbar>
    );
  } else {
    return <></>;
  }
};

export default ErrorNotification;
