export const convertStringToDate = (date: string, hours?: boolean) => {

    if (typeof date === 'string') {
        const newDate = new Date(date);

        if (!hours) {

            return newDate.toLocaleString("fr-fr", {
                year: "numeric",
                month: "short",
                day: "numeric",
            });
        } else {
            newDate.setHours(newDate.getHours() + 1);

            return newDate.toLocaleString("fr-fr", {
                hour: "numeric",
                minute: "numeric",
            });
        }


    } else {
        return date;
    }

}

export const convertStringToDatetime = (date: string) => {

    if (typeof date === 'string') {
        const newDate = new Date(date);

        return newDate.toLocaleString("fr-fr", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
        });
    } else {
        return date;
    }

}

export const checkIfDateIsExpired = (date: string) => {
    if (typeof date === "string") {
        const dateToCheck = new Date(date);
        const now = new Date();

        if (dateToCheck.setHours(0, 0, 0, 0) <= now.setHours(0, 0, 0, 0)) {
            return true;
        }
        return false;
    } else {
        return false;
    }
}

export const formatTimeString = (time: string) => {
    const newTime = time.split(':');
    return newTime[0] + "h" + newTime[1];

};