const RenderActions = (data: any) => {
	return <div></div>;
};

const columns = [
	{
		key: "id",
		label: "ID",
		sortable: true,
		show: true,
		min: true,
		size: 1,
		center: true,
	},
	{
		key: "email",
		label: "EMAIL",
		sortable: true,
		size: 5,
	},
	{
		key: "firstname",
		label: "Prénom",
		sortable: true,
		size: 3,
	},
	{
		key: "name",
		label: "Nom",
		sortable: true,
		size: 3,
	},
	{
		key: "gsm",
		label: "GSM",
		sortable: true,
		size: 2,
	},
	{
		key: "address",
		label: "Adresse",
		sortable: true,
		size: 2,
	},
	{
		key: "cp",
		label: "CP",
		sortable: true,
		size: 1,
	},
	{
		key: "locality",
		label: "Localité",
		sortable: true,
		size: 2,
	},
	{
		key: "active",
		label: "Actif",
		sortable: true,
		min: true,
		size: 1,
		center: true,
	},
	{
		key: "action",
		label: "Actions",
		sortable: false,
		custom: true,
		render: RenderActions,
		size: 2,
		center: true,
	},
];

export default columns;
