/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from "react";
import Select from "react-select";
import {Button} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import {postSession} from "Api/Controllers/SessionsController";
import {getCinemas} from "Api/Controllers/CategoriesController";
import {getMovies} from "Api/Controllers/MoviesController";

function SessionAdd(props: {onCancel: Function; onSave: Function}) {
	const {onCancel, onSave} = props;

	const [session, setSession] = useState({});
	const [cinemas, setCinemas] = useState([]);
	const [movies, setMovies] = useState([]);
	const [selectedCinema, setSelectedCinema] = useState({});
	const [selectedMovie, setSelectedMovie] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		getCinemas().then((result: any) => {
			const cinemas = result.data.map((cinema: any) => {
				return {value: cinema.id, label: cinema.name};
			});
			setSelectedCinema(cinemas[0]);
			setCinemas(cinemas);
		});
		getMovies({
			search: "",
			page: 1,
			sort: "id",
			limit: 10000,
			sort_direction: "ASC",
		}).then((result: any) => {
			const movies = result.data.films.map((movie: any) => {
				return {value: movie.id, label: movie.title};
			});

			setSelectedMovie(movies[0]);
			setMovies(movies);
		});
	}, []);

	const save = () => {
		setLoading(true);

		postSession(session).then((result: any) => {
			cancel();
			onSave();
		});
	};

	const cancel = () => {
		setLoading(false);
		setSession({});
		setSelectedCinema({});
		setSelectedMovie({});
		onCancel();
	};

	return (
		<div className="modal_form">
			<div className="row">
				<div className="col">
					<span>Sélectionner un cinéma</span>
					<Select
						aria-label="Cinéma"
						placeholder="Cinéma"
						className="select"
						value={selectedCinema}
						onChange={(element: any) => {
							setSelectedCinema(element);
							setSession({...session, id_cinema: element.value});
						}}
						options={cinemas}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<span>Sélectionner un film</span>
					<Select
						aria-label="Film"
						placeholder="Film"
						className="select"
						value={selectedMovie}
						onChange={(element: any) => {
							setSelectedMovie(element);
							setSession({...session, id_film: element.value});
						}}
						options={movies}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<span>Date</span>

					<input
						type="date"
						onChange={(element) => {
							setSession({...session, datesession: element.target.value});
						}}
					/>
				</div>
				<div className="col">
					<span>Heure</span>

					<input
						type="time"
						onChange={(element) => {
							setSession({...session, hour: element.target.value});
						}}
					/>
				</div>
			</div>
			<div className="row actions">
				<div className="col6">
					<Button onClick={cancel} variant="outlined">
						Annuler
					</Button>
				</div>
				<div className="spacer"></div>
				<div className="col6">
					<LoadingButton loading={loading} onClick={save} variant="contained">
						Ajouter
					</LoadingButton>
				</div>
			</div>
		</div>
	);
}

export default SessionAdd;
