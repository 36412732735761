/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import Skeleton from "@mui/material/Skeleton";
import Button from "@mui/material/Button";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

/** Modules  */
import Notifications from "Components/Parts/Notifications";
import AccountIcon from "Components/Parts/Account";
import Logout from "Components/Parts/Logout";

/** Redux */
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { getUserId, getUser } from "Redux/Reducers/User/UserSlice";
import { setError } from "Redux/Reducers/App/AppSlice";

/** Images */
const logo = require("Assets/Images/logo/logo.png");

function Dashboard() {
	const { t } = useTranslation();
	const currentUser: any = useAppSelector(getUser);
	const currentUserId = useAppSelector(getUserId);
	const [openModalProject, setOpenModalProject] = useState(false);
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (currentUserId) {
			
		}
	}, [currentUserId, dispatch]);

	return (
		<div className="Page dashboard">
			<header>
				<div className="pageName">
				</div>
				<Notifications />
			</header>

			<div className="content">
				<div className="row">
				
				</div>
			
			</div>
		</div>
	);
}

export default Dashboard;
