/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";

/** Modules  */
import Search from "Components/Parts/Search";
import columnsDefinition from "./Columns";

/** Components */

import Table from "Components/Parts/Table";

/** API */

import {getUsers} from "Api/Controllers/UserController";

function RenderFlag(item: {props: any}, column: {key: string; label: string}) {
	const {key, label} = column;
	const film = item.props;

	return (
		<div
			className={
				key && parseInt(film[key]) === 1 ? "active flagButton" : " flagButton"
			}
		>
			{key && parseInt(film[key]) === 1 ? (
				<CheckCircleIcon />
			) : (
				<RadioButtonUncheckedIcon />
			)}
		</div>
	);
}

function RenderAction(
	item: {props: any},
	column: any,
	pagination: {page: number},
	navigate: Function
) {
	const user = item.props;
	//const navigate = useNavigate();

	const openDetails = () => {
		navigate("/utilisateurs/" + user.id, {
			state: {currentPage: pagination.page},
		});
	};

	return (
		<button onClick={openDetails} className="action">
			<EditIcon />
		</button>
	);
}

function Users() {
	const {state} = useLocation();

	const [loading, setLoading] = useState(true);

	const [usersList, setUsersList] = useState([] as any[]);
	const [searchedValue, setSearchedValue] = useState("");
	const [filters, setFilters] = useState({
		search: "",
		page: 1,
	} as any);
	const [columns, setColumns] = useState(columnsDefinition);

	const [currentPage, setCurrentPage] = useState(1);
	const [pagination, setPagination] = useState({} as any);

	const GetUsersList = async () => {
		setLoading(true);
		const response: any = await getUsers(filters);

		const datas: any = [];
		setLoading(false);

		const currentPagination = {
			page: response.data.currentpage,
			total: response.data.nbpage,
		};

		setPagination(currentPagination);

		if (response.data.users.length > 0) {
			for (const user of response.data.users) {
				datas.push({
					id: user.id,
					email: user.email,
					firstname: user.firstname,
					name: user.name,
					gsm: user.gsm,
					cp: user.cp,
					address: user.address,
					locality: user.locality,
					active: RenderFlag,
					action: RenderAction,
					props: user,
				});
			}

			setUsersList(datas);
		}
	};

	useEffect(() => {
		setColumns(columnsDefinition);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		GetUsersList();
	}, [filters]);

	useEffect(() => {
		state && setCurrentPage(state.currentPage);
	}, [state]);

	return (
		<div className="Page listing">
			<header>
				<div className="pageName">
					<strong>Liste des utilisateurs</strong>
				</div>
				<Search
					label="Rechercher un utilisateur"
					value={searchedValue}
					onChange={(value: string) => {
						setSearchedValue(value);
						setFilters({...filters, search: value, page: 1});
					}}
				/>
			</header>

			<div className="content">
				<div className="row">
					<Table
						datas={usersList}
						columns={columns}
						loading={loading}
						pagination={pagination}
						onPageChange={(page: number) => setFilters({...filters, page})}
						onSort={(column: string, order: string) => {
							setFilters({...filters, sort: column, sort_direction: order});
						}}
					/>
				</div>
			</div>
		</div>
	);
}

export default Users;
