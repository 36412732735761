/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";
import "dayjs/locale/fr";

import NoMealsIcon from "@mui/icons-material/NoMeals";
import NoCellIcon from "@mui/icons-material/NoCell";

import { getScreen } from "Api/Controllers/ScreensController";

import { getSessions } from "Api/Controllers/SessionsController";

/** SVG */

import { ReactComponent as SauveniereIcon } from "Assets/Images/logo/sauveniere.svg";
import { ReactComponent as ParcIcon } from "Assets/Images/logo/parc.svg";
import { ReactComponent as ChurchillIcon } from "Assets/Images/logo/churchill.svg";
import { ReactComponent as CameoIcon } from "Assets/Images/logo/cameo.svg";

import Logo from "Assets/Images/logo/logo.png";
import Bottom from "Assets/Images/screens/bottom.png";
import Grignoux from "Assets/Images/screens/grignoux.png";

const jours = [
	"Dimanche",
	"Lundi",
	"Mardi",
	"Mercredi",
	"Jeudi",
	"Vendredi",
	"Samedi",
];
dayjs.locale("fr-fr");
const now = dayjs();

function Show() {
	const { id } = useParams();

	const [pictures, setPictures] = useState<any[]>([]);
	const [loading, setLoading] = useState(true);

	const [showSessions, setShowSessions] = useState(false);
	const [activeKey, setActiveKey] = useState(0);

	const [screen, setScreen] = useState<any>({});

	const [session, setSessions] = useState<any[]>([]);
	const [reloadFromSession, setReloadFromSession] = useState(0);

	const defaultDelayTime = 20000;
	let delayTime = defaultDelayTime;

	const placeId = id ? parseInt(id) : 0;

	useEffect(() => {
		getScreen(placeId, true).then((response: any) => {
			if (
				response.infos.status === "only_sessions" ||
				response.infos.status === "active"
			) {
				setShowSessions(true);
			} else {
				setLoading(false);
			}

			setScreen(response.infos);
			setPictures(response.pictures);
			setActiveKey(0);
		});
	}, []);

	useEffect(() => {
		if (screen.id_place) {
			const refreshSessions = () => {
				const params = {
					id_cinema: screen.id_place,
					today: true,
					limit: 6,
				};
				setReloadFromSession(reloadFromSession + 1);
				setLoading(true);

				getSessions(params).then((response: any) => {
					if (response?.data?.session) {
						if (response.data.session.length > 0) {
							setSessions(response.data.session);
							setTimeout(() => {
								setLoading(false);
							}, 600);
						} else {
							setSessions([]);
							setLoading(false);
							const newScreen = { ...screen, status: "only_pictures" };
							setScreen(newScreen);
						}
					} else {
						setSessions([]);
						const newScreen = { ...screen, status: "only_pictures" };
						setScreen(newScreen);
						setTimeout(() => {
							setLoading(false);
						}, 300);
					}
				});
			};

			const interval = 1000 * 60 * 5; // every 5 minutes
			refreshSessions();
			setInterval(refreshSessions, interval);
		}
	}, [screen.id_place]);

	useEffect(() => {
		if (screen.status === "active") {
			nextElement("*");
		}
	}, [showSessions]);

	useEffect(() => {
		if (screen.status === "only_pictures") {
			nextElement("picture");
		}
	}, [screen.status, activeKey]);

	const nextElement = (type = "string") => {
		const currentPicture = pictures[activeKey];
		if (
			currentPicture?.duration &&
			currentPicture?.duration > 0 &&
			currentPicture?.duration !== "0"
		) {
			delayTime = parseInt(currentPicture?.duration) * 1000;
		}
		if (type === "picture") {
			setShowSessions(false);
			setTimeout(() => {
				if (activeKey + 1 === pictures.length) {
					window.location.reload();
				}

				let currentKey = activeKey + 1 === pictures.length ? 0 : activeKey + 1;
				setActiveKey(currentKey);
			}, delayTime);
		} else {
			if (showSessions) {
				delayTime = defaultDelayTime;
			}
			setTimeout(() => {
				setShowSessions(!showSessions);

				if (!showSessions) {
					setTimeout(() => {
						if (activeKey + 1 === pictures.length) {
							window.location.reload();
						}
						let currentKey =
							activeKey + 1 === pictures.length ? 0 : activeKey + 1;
						setActiveKey(currentKey);
					}, 300);
				}
			}, delayTime);
		}
	};

	return (
		<div className="show">
			{!loading ? (
				<>
					{screen.status !== "offline" ? (
						<>
							{(screen.status === "active" ||
								screen.status === "only_sessions") && (
								<div
									className={
										showSessions || pictures.length === 0
											? "show session"
											: "session"
									}
								>
									<div className="content">
										<div className="head">
											{parseInt(screen?.id_place) === 1 && (
												<ParcIcon className="place_icon" />
											)}
											{parseInt(screen?.id_place) === 2 && (
												<ChurchillIcon className="place_icon" />
											)}
											{parseInt(screen?.id_place) === 3 && (
												<SauveniereIcon className="place_icon" />
											)}
											{parseInt(screen?.id_place) === 4 && (
												<CameoIcon className="place_icon" />
											)}
											<div className={"title color" + screen?.id_place}>
												<span>prochaines</span>
												<strong>Séances</strong>
											</div>
											<div className={"date color" + screen?.id_place}>
												<span>ce {jours[now.day()]}</span>
												<strong>{now.format("DD MMMM")}</strong>
											</div>
										</div>
										<ul className="session_content">
											{session.map((session, index) => (
												<li
													className={"session_item color" + session.id_cinema}
													key={index}
												>
													<span className="hour">
														{session.hour.substr(0, session.hour.length - 3)}
													</span>
													<span className="title">{session.title}</span>
													<span className="room">
														{session.auditorium_name}
													</span>
												</li>
											))}
										</ul>

										<div className="respect" style={{ display: "none" }}>
											<span className="">
												Pour le respect de tous, il est interdit de manger et
												d'utiliser son téléphone dans nos salles.
											</span>
											<NoMealsIcon className="no_icon" />
											<NoCellIcon className="no_icon" />
										</div>
									</div>

									<div className="progra">
										<img className="bottom" src={Bottom} alt="" />
										<div className="content">
											<span>Retrouvez notre</span>{" "}
											<strong>programmation</strong> <span>sur</span>{" "}
											<strong>www.grignoux.be</strong>
											<img className="grignoux" src={Grignoux} alt="" />
										</div>
									</div>
								</div>
							)}
							{(screen.status === "active" ||
								screen.status === "only_pictures") && (
								<div
									className={
										showSessions || pictures.length === 0
											? "picture"
											: "show picture"
									}
								>
									<img src={pictures[activeKey]?.path} alt="" />
								</div>
							)}
						</>
					) : (
						<div className="offline">
							<img src={Logo} alt="Les Grignoux" />
						</div>
					)}
				</>
			) : (
				<div className="offline loading">
					<img src={Logo} alt="Les Grignoux" style={{ opacity: 0.3 }} />
				</div>
			)}
		</div>
	);
}

export default Show;
