/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import {
	Dialog,
	Switch,
	FormControl,
	InputLabel,
	OutlinedInput,
	ListItemText,
	Checkbox,
	MenuItem,
} from "@mui/material";

import Select, { SelectChangeEvent } from "@mui/material/Select";

const days = [
	"lundi",
	"mardi",
	"mercredi",
	"jeudi",
	"vendredi",
	"samedi",
	"dimanche",
];

function Modal_details(props: {
	image: any;
	onClose: Function;
	open: boolean;
}) {
	const defaultDuration = 10;
	const { image, onClose, open } = props;

	const [imageDetails, setImageDetails] = useState<any>(image);

	const [selectedDays, setSelectedDays] = React.useState<string[]>([]);

	const selectDay = (event: SelectChangeEvent<typeof selectedDays>) => {
		const {
			target: { value },
		} = event;
		const valueArray = typeof value === "string" ? value.split(",") : value;
		setSelectedDays(valueArray);

		setImageDetails({ ...imageDetails, availability: valueArray?.join(",") });
	};

	const handleClose = () => {
		const imageToSave = structuredClone(imageDetails);
		onClose(imageToSave);
		setSelectedDays([]);
		setImageDetails({
			id: 0,
			duration: defaultDuration,
			active: "0",
			path: "",
			name: "",
			availability: "",
			start_date: "",
			end_date: "",
			start_hour: "",
			end_hour: "",
		});
	};


	useEffect(() => {
		if (image.id) {
			setImageDetails(image);
			if (
				image?.availability !== "" ||
				image?.availability !== null ||
				image?.availability !== undefined ||
				image?.availability !== "0"
			) {
				setSelectedDays(image?.availability?.split(","));
			}
		}
	}, [image, open]);

	return (
		<Dialog className="image_modal" open={open} onClose={handleClose}>
			{imageDetails.id ? (
				<div className="modalContent image_details">
					<img src={imageDetails.path} alt={imageDetails.name} />
					<div className="info">
						<div className="header">
							<strong className="title">
								{imageDetails?.name?.length > 50
									? imageDetails?.name.substring(0, 50) + "..."
									: imageDetails?.name}
							</strong>
						</div>

						<div className="tabs">
							<div className="tab_content">
								<div className="image_infos">
									<div className="switch_field">
										<label>Information de l'image</label>
									</div>

									<div className="content">
										<div className="row">
											<label>Durée de l'affichage</label>
											<div className="line right">
												<strong>{imageDetails.duration} </strong>
												<span> sec.</span>
											</div>
										</div>
										<div className="row">
											<label>Date de début</label>
											<div className="line right">
												<strong>{imageDetails.start_date} </strong>
											</div>
										</div>
										<div className="row">
											<label>Date de fin</label>
											<div className="line right">
												<strong>{imageDetails.end_date ? imageDetails.end_date : 'aucune date de fin'} </strong>
											</div>
										</div>

									</div>
								</div>
							</div>
							<div className="tab_content">
								<div className="hours_details select_days">
									<div className="switch_field">
										<label>Disponibilité</label>
									</div>

									<div className="content col">
										<span>Définir les jours d'affichage</span>
										<FormControl sx={{ m: 1, width: '96%' }}>
											<InputLabel id="days">Jours</InputLabel>
											<Select
												labelId="days"
												multiple
												value={
													Array.isArray(selectedDays) ? selectedDays : []
												}
												onChange={selectDay}
												input={<OutlinedInput label="Tag" />}
												renderValue={(selected) => selected.join(", ")}
											>
												{days.map((day) => (
													<MenuItem key={day} value={day}>
														<Checkbox
															checked={selectedDays?.indexOf(day) > -1}
														/>
														<ListItemText primary={day} />
													</MenuItem>
												))}
											</Select>
										</FormControl>

									</div>

									<div className="content">
										<div className="row">
											<div className="input_field">
												<label>Heure de début</label>
												<input
													type="time"
													name="start_hour"
													value={imageDetails.start_hour}
													onChange={(e) => {
														setImageDetails({
															...imageDetails,
															start_hour: e.target.value,
														});
													}}
												/>
											</div>
										</div>
										<div className="row">
											<div className="input_field">
												<label>Heure de fin</label>
												<input
													type="time"
													name="end_hour"
													value={imageDetails.end_hour}
													onChange={(e) => {
														setImageDetails({
															...imageDetails,
															end_hour: e.target.value,
														});
													}}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<span>Chargement</span>
			)
			}
		</Dialog >
	);
}

export default Modal_details;
