export interface ProjectState {
    id: number;
    contractors: Array<ContractorType>;
    datas: ProjectType,
    coordinates: any;
    attributes: any;
    loading: boolean;
}
export type ProjectType = {
    id_country: string;
    id_parent: string | null;
    id_user: number;
    address: string;
    budget: number;
    city: string;
    description: string;
    name: string;
    start_date?: string;
    zip: string;
    uuid: string;
    sharing_ics: boolean;
    attr?: any;
};
export type ContractorType = {
    id: number;
    name: string;
    color: string;
    function: string;
    fulladdress: string;
    email: string;
    phone: string;
    firstname: string;
    company: string;
};
export type ContributorEventType = {
    id: number
};
export type BudgetType = {
    expenses: [],
    expenses_by_category: [],
    estimate_by_category: [],
    budget: number,
    total_expenses: number,
    total_estimates: number
};
export const defaultContractor: ContractorType = {
    id: 0,
    name: "",
    color: "",
    function: "",
    fulladdress: "",
    email: "",
    phone: "",
    firstname: "",
    company: "",
}
export const defaultProject: ProjectType = {
    id_country: "BE",
    id_parent: null,
    id_user: 0,
    address: "",
    budget: 0,
    city: "",
    description: "",
    name: "",
    zip: "",
    uuid: "",
    sharing_ics: false,
}
export const initialState: ProjectState = {
    id: 0,
    contractors: [],
    coordinates: { lat: '', lng: '' },
    datas: defaultProject,
    attributes: {},
    loading: true
};

