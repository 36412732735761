/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
	TextField,
	Switch,
	Card,
	CardContent,
	Grid,
	Snackbar,
	Alert,
	Dialog,
	Button,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";

import SaveIcon from "@mui/icons-material/Save";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

/** Modules  */

/** Components */

/** API */

import { getUser, putUser } from "Api/Controllers/UserController";

function User() {
	const navigate = useNavigate();
	const { state } = useLocation();
	const { id } = useParams();

	const [loading, setLoading] = useState(false);
	const [user, setUser] = useState({} as any);

	const [successEdit, setSuccessEdit] = useState(false);
	const [errorEdit, setErrorEdit] = useState(false);

	const [newPassword, setNewPassword] = useState("");
	const [openConfirm, setOpenConfirm] = useState(false);

	useEffect(() => {
		const currentId = id ? parseInt(id) : 0;
		getUser(currentId).then(
			(result: any) => {
				setUser(result.data);
			},
			(error) => {
				console.log(error);
			}
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const edit = () => {
		setLoading(true);
		putUser(user).then(
			(result: any) => {
				setLoading(false);
				setSuccessEdit(true);
				setErrorEdit(false);
			},
			(error: any) => {
				setLoading(false);
				setSuccessEdit(false);
				setErrorEdit(true);
			}
		);
	};

	const onClose = () => {
		setErrorEdit(false);
		setSuccessEdit(false);
	};

	const confirmChangePassword = () => {
		setOpenConfirm(true);
	};

	const changePassword = () => {
		setLoading(true);

		setTimeout(() => {
			setLoading(false);
		}, 1000);
		/*
		changePassword({ id_user: user.id, new_password: newPassword }).then(
			(result: any) => {
				setLoading(false);
				setSuccessEdit(true);
				setErrorEdit(false);
			},
			(error: any) => {
				setLoading(false);
				setSuccessEdit(false);
				setErrorEdit(true);
			}
		);
		*/
	};

	return (
		<div className="Page single user">
			<Dialog open={openConfirm} onClose={() => setOpenConfirm(false)}>
				<div className="dialog">
					Confirmer le changement de mot de passe ?
					<div className="actions">
						<Button variant="text" onClick={() => setOpenConfirm(false)}>
							annuler
						</Button>
						<LoadingButton loading={loading} variant="contained" onClick={changePassword}>
							confirmer
						</LoadingButton>

					</div>
				</div>
			</Dialog>
			<Snackbar
				open={successEdit}
				autoHideDuration={3000}
				onClose={onClose}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
			>
				<Alert onClose={onClose} severity="success" sx={{ width: "100%" }}>
					L'utilisateur a bien été modifié.
				</Alert>
			</Snackbar>
			<Snackbar
				open={errorEdit}
				autoHideDuration={3000}
				onClose={onClose}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				style={{ marginRight: "14em" }}
			>
				<Alert onClose={onClose} severity="error" sx={{ width: "100%" }}>
					Une erreur est survenue.
				</Alert>
			</Snackbar>
			<header>
				<div className="pageName">
					<button
						className="goBack"
						onClick={() => navigate("/utilisateurs", { state })}
					>
						<KeyboardBackspaceIcon /> retour
					</button>
					<div className="userHeader">
						<img
							className="userPicture"
							src={user.imageUri}
							alt={user.firstname}
						/>
						<strong>
							{user.firstname} {user.name}
						</strong>
					</div>
					<LoadingButton
						className="saveButton"
						onClick={edit}
						loading={loading}
						variant="contained"
						endIcon={<SaveIcon />}
					>
						Enregistrer
					</LoadingButton>
				</div>
			</header>

			<div className="content userInfos">
				{user && (
					<>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<Card className="card">
									<div className="title">Informations générales</div>
									<CardContent>
										<Grid container spacing={2}>
											<Grid item xs={12} md={6}>
												<TextField
													className="input"
													label="Prénom"
													variant="outlined"
													onChange={(e: any) => {
														const currentUser = {
															...user,
															firstname: e.target.value,
														};
														setUser(currentUser);
													}}
													value={user?.firstname ? user.firstname : ""}
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<TextField
													className="input"
													label="Nom"
													variant="outlined"
													onChange={(e: any) => {
														const currentUser = {
															...user,
															name: e.target.value,
														};
														setUser(currentUser);
													}}
													value={user?.name ? user.name : ""}
												/>
											</Grid>
										</Grid>

										<TextField
											className="input"
											label="Email"
											variant="outlined"
											onChange={(e: any) => {
												const currentUser = {
													...user,
													email: e.target.value,
												};
												setUser(currentUser);
											}}
											value={user?.email ? user.email : ""}
										/>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={12} md={6}>
								<Card className="card">
									<div className="title">Adresse</div>
									<CardContent>
										<Grid container spacing={2}>
											<Grid item xs={12} md={4}>
												<TextField
													className="input"
													label="Code postal"
													variant="outlined"
													onChange={(e: any) => {
														const currentUser = {
															...user,
															cp: e.target.value,
														};
														setUser(currentUser);
													}}
													value={user?.cp ? user.cp : ""}
												/>
											</Grid>
											<Grid item xs={12} md={8}>
												<TextField
													className="input"
													label="Localité"
													variant="outlined"
													onChange={(e: any) => {
														const currentUser = {
															...user,
															locality: e.target.value,
														};
														setUser(currentUser);
													}}
													value={user?.locality ? user.locality : ""}
												/>
											</Grid>
										</Grid>

										<TextField
											className="input"
											label="Rue"
											variant="outlined"
											onChange={(e: any) => {
												const currentUser = {
													...user,
													address: e.target.value,
												};
												setUser(currentUser);
											}}
											value={user?.address ? user.address : ""}
										/>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
						<br />

						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<Card className="card">
									<div className="title">Anniversaire</div>
									<CardContent>
										<Grid container spacing={2}>
											<Grid item xs={12} md={6}>
												<TextField
													className="input"
													label="Date de naissance"
													variant="outlined"
													type={"date"}
													onChange={(e: any) => {
														const currentUser = {
															...user,
															birthdate: e.target.value,
														};
														setUser(currentUser);
													}}
													value={user?.birthdate ? user.birthdate : ""}
												/>
											</Grid>
										</Grid>

										<span>Place anniversaire dsponible</span>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={12} md={6}>
								<Card className="card">
									<div className="title">
										<span>Mot de passe</span>
										<LoadingButton
											className="saveHeaderButton"
											onClick={confirmChangePassword}
											loading={loading}
											variant="contained"
										>
											Modifier le mot de passe
										</LoadingButton>
									</div>
									<CardContent>
										<span>Définir un nouveau mot de passe.</span>

										<TextField
											className="input"
											label="Mot de passe"
											variant="outlined"
											value={newPassword}
											onChange={(e: any) => {
												setNewPassword(e.target.value);
											}}
										/>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</>
				)}
			</div>
		</div>
	);
}

export default User;
