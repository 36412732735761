import React, {useEffect} from "react";
import {useParams, useNavigate} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

/** Components */
import Navigation from "Components/Parts/Navigation";
import LightBox from "Components/Parts/Lightbox";
import ErrorNotification from "Components/Parts/ErrorNotification";

/** Pages */

import Dashboard from "./Dashboard/Index";
import Admins from "./Admins/Index";
import Users from "./Users/Index";
import UserDetails from "./Users/Single";
import Settings from "./Settings/Index";
import Reports from "./Reports/Index";
import Movies from "./Movies/Index";
import Sessions from "./Sessions/Index";
import SessionDetails from "./Sessions/Session_edit";
import Screens from "./Screens/Index";
import ScreensDetails from "./Screens/Screen_edit";
import MediasList from "./Screens/Medias";
import Scan from "./Scan/Index";

/** Redux */
import {useAppDispatch, useAppSelector} from "Redux/hooks";
import {getUser, setUser} from "Redux/Reducers/User/UserSlice";
import {clearEvents} from "Redux/Reducers/Project/EventsSlice";

/** Api */

import adminApiObject from "Api/Models/Admin";
const adminApi = new adminApiObject();

/** Pages */
const pagesRoutes = [
	"films",
	"seances",
	"utilisateurs",
	"parametres",
	"accueil",
	"rapports",
	"administrateurs",
	"scan",
	"ecrans",
	"medias",
];

function Page({navigation}) {
	const {page, id} = useParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const currentUser = useAppSelector(getUser);

	const [loading, setLoading] = React.useState(true);
	let currentComponent: string = page ? page : "";

	if (currentComponent === "" || currentComponent === undefined) {
		currentComponent = "films";
	}

	useEffect(() => {
		dispatch(clearEvents({}));
		if (!pagesRoutes.includes(currentComponent)) {
			navigate("/notfound");
		} else {
			// !currentUser.logged && dispatch(checkToken({}));
		}
	}, [currentComponent, currentUser, dispatch, navigate]);

	useEffect(() => {
		adminApi.checkToken().then(
			(result: any) => {
				dispatch(setUser(result));
				setLoading(false);
			},
			(error) => {
				window.location.href = "/login";
			}
		);
	}, []);

	return (
		<div className="App">
			{loading ? (
				<div className="loader">
					<CircularProgress />
				</div>
			) : (
				<>
					<div className={"applicationContainer"}>
						<Navigation current={currentComponent} />
						{currentComponent === "films" && <Movies />}
						{currentComponent === "seances" &&
							(id === "" || id === undefined) && <Sessions />}
						{currentComponent === "seances" && id && parseInt(id) > 0 && (
							<SessionDetails />
						)}
					
						{currentComponent === "rapports" && <Reports />}

						{currentComponent === "administrateurs" && <Admins />}
						{currentComponent === "parametres" && <Settings />}
						{false && currentComponent === "accueil" && <Dashboard />}
						{currentComponent === "scan" && <Scan />}
						{currentComponent === "medias" && <MediasList />}

						{currentComponent === "utilisateurs" &&
							(id === "" || id === undefined) && <Users />}

						{currentComponent === "utilisateurs" && id && parseInt(id) > 0 && (
							<UserDetails />
						)}
						{currentComponent === "ecrans" &&
							(id === "" || id === undefined) && <Screens />}

						{currentComponent === "ecrans" && id && parseInt(id) > 0 && (
							<ScreensDetails />
						)}
					</div>
				</>
			)}
			<LightBox />
			<ErrorNotification />
		</div>
	);
}

export default Page;
