export function getErrorLabel(error: string) {

    const labels = {
        "filmnotfound": "Film introuvable",
        "cinemanotfound": "Cinéma introuvable",
        "sessionalreadyexists": "Séance existante",
        "badformat": "Mauvais format de données",

    }

    return labels[error] || "Erreur inconnue";
}