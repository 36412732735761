const columns = [
	{
		key: "name",
		label: "Nom",
		sortable: true,
		size: 4,
	},
	{
		key: "upload_time",
		label: "Date d'ajout",
		sortable: true,
		show: true,
		min: true,
		size: 3,
		type: "date",
	},
	{
		key: "bookmark",
		label: "Favoris",
		sortable: true,
		size: 1,
		editable: true,
		type: "boolean",
	},
	
	{
		key: "action",
		label: "Actions",
		sortable: false,
		custom: true,
		size: 2,
	},
];

export default columns;
