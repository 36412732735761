const RenderActions = (data: any) => {
	return <div></div>;
};

const columns = [
	{
		key: "id",
		label: "ID",
		sortable: true,
		show: true,
		min: true,
		size: 0.8,
		center: true,
		type: "number",
	},
	{
		key: "datesession",
		label: "Date",
		sortable: true,
		size: 1.2,
		center: true,
		editable: true,
		type: "date",
	},
	{
		key: "hour",
		label: "Heure",
		sortable: true,
		size: 1,
		center: true,
		editable: true,
		type: "time",
	},
	{
		key: "title",
		label: "Film",
		sortable: true,
		size: 3.3,
		type: "string",
	},
	{
		key: "cinema",
		label: "Cinéma",
		sortable: true,
		show: true,
		min: true,
		size: 1,
		type: "string",
	},

	{
		key: "auditorium_name",
		label: "Salle",
		sortable: true,
		show: true,
		min: true,
		size: .75,
		editable: true,
		type: "string",
	},

	{
		key: "perf_id",
		label: "PERF. ID",
		sortable: true,
		show: true,
		min: true,
		size: 2,
		editable: true,
		type: "string",
	},
	{
		key: "auditorium_id",
		label: "Adit. ID",
		sortable: true,
		show: true,
		min: true,
		size: 2,
		editable: true,
		type: "string",
	},

	{
		key: "derniereseance",
		label: "Dernière",
		sortable: true,
		min: true,
		size: .75,
		center: true,
		editable: true,
		type: "boolean",
	},

	{
		key: "complete",
		label: "Complète",
		sortable: true,
		min: true,
		size: .75,
		center: true,
		editable: true,
		type: "boolean",
	},
	{
		key: "actions",
		label: "Actions",
		sortable: false,
		custom: true,
		render: RenderActions,
		size: .5,
		type: "Function",
	},
];

export default columns;
