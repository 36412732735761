import { defaultReturnObject, ApiReturnDatas } from "Types/Api";

import moviesApiObject from "Api/Models/Movies";
const moviesApi = new moviesApiObject();

export const getMovies = (datas: any) => {
  const returnDatas: ApiReturnDatas = defaultReturnObject;

  return new Promise((resolve, reject) => {
    moviesApi.getList(datas).then(
      (result: any) => {
        returnDatas.status = "success";
        returnDatas.message = "request_success";
        returnDatas.data = result;
        resolve(returnDatas);
      },
      (error) => {
        returnDatas.message = "request_failure";
        reject(returnDatas);
      }
    );
  });
};
