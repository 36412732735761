import screensApiObject from "Api/Models/Screens";
const screensApi = new screensApiObject();

export const getScreens = (datas: any) => {
	return new Promise((resolve, reject) => {
		datas.limit = 40;
		screensApi.getList(datas).then(
			(result: any) => {
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
};

export const getScreen = (id: number, filter?: boolean) => {
	return new Promise((resolve, reject) => {
		screensApi.getOne(id, filter).then(
			(result: any) => {
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
};

export const postScreen = (datas: any) => {
	return new Promise((resolve, reject) => {
		screensApi.post(datas).then(
			(result: any) => {
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
};

export const deleteScreen = (id: number) => {
	return new Promise((resolve, reject) => {
		screensApi.delete(id).then(
			(result: any) => {
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
};

export const updateScreen = (datas: any) => {
	return new Promise((resolve, reject) => {
		screensApi.put(datas).then(
			(result: any) => {
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
};

export const getMedias = (datas: any) => {
	return new Promise((resolve, reject) => {
		screensApi.getMedias(datas).then(
			(result: any) => {
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
};

export const postMedia = (datas: { name: string; base64: string }) => {
	return new Promise((resolve, reject) => {
		screensApi.postMedia(datas).then(
			(result: any) => {
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
};

export const updatePictures = (datas: { id_screen: number; pictures: any }) => {
	return new Promise((resolve, reject) => {
		screensApi.updatePictures(datas).then(
			(result: any) => {
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
};

export const editPicture = (datas: any) => {
	return new Promise((resolve, reject) => {
		screensApi.editPicture(datas).then(
			(result: any) => {
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
};
export const editMedia = (datas: any) => {
	return new Promise((resolve, reject) => {
		screensApi.editMedia(datas).then(
			(result: any) => {
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
};

export const postPicture = (datas: any) => {
	return new Promise((resolve, reject) => {
		screensApi.postPicture(datas).then(
			(result: any) => {
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
};

export const deletePicture = (id: number) => {
	return new Promise((resolve, reject) => {
		screensApi.deletePicture(id).then(
			(result: any) => {
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
};

export const deleteMedia = (id: number) => {
	return new Promise((resolve, reject) => {
		screensApi.deleteMedia(id).then(
			(result: any) => {
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
};

export const postLinkmedia = (datas: any) => {
	return new Promise((resolve, reject) => {
		screensApi.postLinkmedia(datas).then(
			(result: any) => {
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
};

export const updateMediaAndLinks = (datas: any) => {
	return new Promise((resolve, reject) => {
		screensApi.updateMediaAndLinks(datas).then(
			(result: any) => {
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
};

export const deleteLinkMedia = (id_screen: number, id_media: number) => {
	return new Promise((resolve, reject) => {
		screensApi.deleteLinkMedia(id_screen, id_media).then(
			(result: any) => {
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
};
