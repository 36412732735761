import categoryApiObject from "Api/Models/Categories";
const categoryApi = new categoryApiObject();

export const getCinemas = () => {
	return new Promise((resolve, reject) => {
		categoryApi.getCinemas().then(
			(result: any) => {
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
};
