/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Dialog, DialogActions, Switch, Alert, Snackbar, Button, DialogContent } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { updateMediaAndLinks } from "Api/Controllers/ScreensController";
import { getScreens } from "Api/Controllers/ScreensController";

const NOW = new Date().toISOString().split('T')[0];

function Modal_details(props: {
	image: any;
	onClose: Function;
	open: boolean;
}) {
	const defaultDuration = 20;
	const { image, onClose, open } = props;
	const [imageDetails, setImageDetails] = useState<any>(image);
	const [listScreens, setListScreens] = useState<any[]>([]);
	const [selectedScreen, setSelectedScreen] = useState<any>([]);
	const [selectedScreenIds, setSelectedScreenIds] = useState<any>([]);
	const [processSaving, setProcessSaving] = useState(false);

	const [feedback, setFeedback] = useState("");
	const [feedbackMessage, setFeedbackMessage] = useState("");
	const [hasEdition, setHasEdition] = useState(false);
	const [showEditionWarning, setShowEditionWarning] = useState(false);


	React.useMemo(() => {
		getScreens({}).then((response: any) => {
			setListScreens(response);
		});
	}, []);

	React.useEffect(() => {
		if (image && image?.id) {
			image.duration = parseInt(image.duration) > 0 ? image.duration : defaultDuration;
			setImageDetails(image);
			if (image?.screens) {
				setSelectedScreen(image.screens);
			}
		}
	}, [image]);

	React.useEffect(() => {
		setSelectedScreenIds(selectedScreen.map((item: any) => item.id))
	}, [selectedScreen]);


	const handleClose = () => {
		if (hasEdition) {
			setShowEditionWarning(true);
		} else {
			proceedClose();
		}
	};

	const proceedClose = () => {
		setShowEditionWarning(false);
		setHasEdition(false);
		const imageToSave = structuredClone(imageDetails);
		imageToSave.screens = selectedScreen;
		onClose(imageToSave);

		setImageDetails({
			id: 0,
			screens: [],
			name: "",
			path: "",
		});
	};

	const triggerFeedback = (type: string, message: string) => {
		setFeedback(type);
		setFeedbackMessage(message);

		setTimeout(() => {
			setFeedback("");
			setFeedbackMessage("");
		}, 3000);
	}
	const save = (proceedClose: boolean) => {
		setProcessSaving(true);

		imageDetails.start_date = imageDetails.start_date ? imageDetails.start_date : NOW;
		delete imageDetails.id_media;
		delete imageDetails.pictures;
		delete imageDetails.screens;

		const screens = listScreens.map(screen => {
			let action = "delete";
			if (selectedScreenIds.includes(screen.id)) {
				action = "add";
			}
			return { id: screen.id, action };
		})

		updateMediaAndLinks({ media: imageDetails, screens }).then(
			response => {
				setProcessSaving(false);
				triggerFeedback("success", "Modifications enregistrées");
				proceedClose && setTimeout(handleClose, 500);
				setHasEdition(false);
			}, error => {
				setProcessSaving(false);
				triggerFeedback("error", "Impossible de sauvegarder les données.");
			}
		)

	};

	return (
		<>
			<Dialog className="image_modal" open={open} onClose={handleClose}>
				{imageDetails.id ? (
					<div className="modalContent image_details modalMedia">
						<img src={imageDetails.path} alt={imageDetails.name} />
						<div className="info">
							<div className="header">
								<label>Nom de l'image</label>
								<input type="text" value={imageDetails?.name}
									onChange={(e) => {
										setImageDetails({ ...imageDetails, name: e.target.value });
										setHasEdition(true);
									}}
									className="title" />
							</div>
							<div className="screens">
								<strong>Écrans liés:</strong>
								<div className=" row">
									{listScreens.map((item: any, index: number) => (
										<div className="item" key={index}>
											<span className="label">{item.name}</span>
											<Switch
												aria-label="Ecran sélectionné"
												checked={selectedScreenIds.includes(item.id)}
												onChange={() => {
													let newSelectedScreens = [...selectedScreen];
													if (selectedScreenIds.includes(item.id)) {
														newSelectedScreens = selectedScreen.filter((screen: any) => {
															return screen.id !== item.id;
														});
													} else {
														newSelectedScreens.push(item);
													}
													setSelectedScreen(newSelectedScreens);
													setHasEdition(true);
												}}
											/>
										</div>
									)
									)}
								</div>
							</div>
							<div className="media_info row">
								<span className="label">Durée d'affichage :</span>
								<input type="number"
									value={imageDetails?.duration ? imageDetails.duration : defaultDuration}
									placeholder={defaultDuration.toString()}
									onChange={(e) => {
										setImageDetails({ ...imageDetails, duration: e.target.value });
										setHasEdition(true);
									}} />
								<span>s</span>
							</div>

							<div className="media_info row">
								<span className="label">Date de début d'affichage :</span>
								<input
									type="date"
									name="start_date"
									value={imageDetails?.start_date ? imageDetails.start_date : NOW}
									onChange={(e) => {
										setImageDetails({
											...imageDetails,
											start_date: e.target.value,
										});
										setHasEdition(true);
									}}
								/>
							</div>
							<div className="media_info no_border row">
								<span className="label">Date de fin d'affichage :</span>
								<input
									type="date"
									name="end_date"
									value={imageDetails?.end_date ? imageDetails.end_date : '0000-00-00'}
									onChange={(e) => {
										setImageDetails({
											...imageDetails,
											end_date: e.target.value,
										});
										setHasEdition(true);
									}}
								/>
							</div>

							<div className="media_info warn_message no_border row">
								<span className="label">Ne jamais supprimer cette image :</span>
								<Switch
									aria-label="no delete"
									checked={imageDetails.never_delete === "1"}
									onChange={() => {
										const val = imageDetails.never_delete === "1" ? "0" : "1";
										setImageDetails({ ...imageDetails, never_delete: val });
										setHasEdition(true);
									}}
								/>
							</div>

							<div className="actions">
								{feedback !== "" && (
									<Snackbar
										open={feedback !== ""}
										autoHideDuration={3000}
										anchorOrigin={{ vertical: "top", horizontal: "right" }}
									>
										<Alert severity={feedback === "success" ? "success" : "error"} sx={{ width: "100%" }}>
											{feedbackMessage}
										</Alert>
									</Snackbar>
								)}
								<Button className="closeButton" onClick={handleClose}>Annuler</Button>
								<LoadingButton
									loading={processSaving}
									variant="contained"
									className="saveButton"
									onClick={() => save(false)}
								>
									Enregistrer
								</LoadingButton>
								<LoadingButton
									loading={processSaving}
									variant="contained"
									color="secondary"
									className="saveButton"
									onClick={() => save(true)}
								>
									Enregistrer et quitter
								</LoadingButton>
							</div>

						</div>

					</div>
				) : (
					<span>Chargement</span>
				)}
			</Dialog>
			<Dialog open={showEditionWarning} className="warning_modal">
				<DialogContent>
					<h3>Attention, vous avez des modifications en cours.</h3>
					<br />
					<span>Voulez-vous les <strong>annuler</strong> et <strong>fermer l'édition ?</strong></span>
				</DialogContent>
				<DialogActions>

					<Button className="closeButton" onClick={() => setShowEditionWarning(false)}>Annuler</Button>
					<Button
						variant="contained"
						className="saveButton"
						onClick={proceedClose}
					>
						Fermer
					</Button>
				</DialogActions>
			</Dialog>
		</>

	);
}

export default Modal_details;
