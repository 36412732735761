/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

function LostPage() {
	const {t} = useTranslation();

	return (
		<div className="lost">
			<strong>404</strong>
			<span>Une erreur s'est produite.</span>
		</div>
	);
}

export default LostPage;
