import abstractApiObject from "./abstractApiObject";

class ScreensApiObject extends abstractApiObject {
	constructor() {
		super();
		this.controller = "screens";
	}

	getOne(id: number, filter?: boolean) {
		let url = this.controller + "/" + id;

		if (filter) {
			url += "/filters";
		}
		const params = {
			url: url,
			method: "GET",
			body: {},
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	getMedias(datas: any) {
		let url = this.controller + "/medias?";

		for (const key in datas) {
			url += key + "=" + datas[key] + "&";
		}
		url = url.slice(0, -1);
		const params = {
			url: url,
			method: "GET",
			body: {},
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	postMedia(datas: { name: string; base64: string }) {
		const params = {
			url: this.controller + "/media",
			method: "POST",
			body: datas,
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	updatePictures(datas: { id_screen: number; pictures: any }) {
		const params = {
			url: this.controller + "/pictures",
			method: "POST",
			body: datas,
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	editPicture(datas: any) {
		const params = {
			url: this.controller + "/picture",
			method: "POST",
			body: datas,
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	editMedia(datas: any) {
		const params = {
			url: this.controller + "/media",
			method: "POST",
			body: datas,
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}


	postPicture(datas: any) {
		const params = {
			url: this.controller + "/picture",
			method: "POST",
			body: datas,
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	deletePicture(id: number) {
		const params = {
			url: this.controller + "/picture/" + id,
			method: "DELETE",
			body: { id: id },
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}
	deleteMedia(id: number) {
		const params = {
			url: this.controller + "/media/" + id,
			method: "DELETE",
			body: { id: id },
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	postLinkmedia(datas: any) {
		const params = {
			url: this.controller + "/linkmedia",
			method: "POST",
			body: datas,
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}
	deleteLinkMedia(id_screen: number, id_media: number) {
		const params = {
			url:
				this.controller +
				"/linkmedia?id_screen=" +
				id_screen +
				"&id_media=" +
				id_media,
			method: "DELETE",
			body: {},
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	updateMediaAndLinks(datas: any) {
		const params = {
			url: this.controller + "/mediaandlinks",
			method: "POST",
			body: datas,
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}
}

export default ScreensApiObject;
