import React from "react";

import CircularProgress from "@mui/material/CircularProgress";

import { postMedia } from "Api/Controllers/ScreensController";

const DropUploader = (props: { callback: Function }) => {
	const { callback } = props;

	const inputRef: any = React.useRef(null);

	const [dragActive, setDragActive] = React.useState(false);
	const [progress, setProgress] = React.useState(0);
	const [loading, setLoading] = React.useState(false);

	// handle drag events
	const handleDrag = function (e: any) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === "dragenter" || e.type === "dragover") {
			setDragActive(true);
		} else if (e.type === "dragleave") {
			setDragActive(false);
		}
	};

	// triggers when file is dropped
	const handleDrop = async function (e: any) {
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			// at least one file has been dropped so do something
			handleFiles(e.dataTransfer.files);
		}
	};

	const handleFiles = async (files: any) => {
		const newFiles: any = [];

		setLoading(true);
		setProgress(0);

		await Object.values(files).forEach(async (file, index: number) => {
			const processedFile: any = await processFile(file);

			let extension = "";

			switch (processedFile?.type) {
				case "image/jpeg":
					extension = ".jpg";
					break;
				case "image/png":
					extension = ".png";
					break;
				case "image/gif":
					extension = ".gif";
					break;
			}

			if (extension) {
				const fileName = processedFile?.name.replace(extension, "");

				const newFile = {
					base64: processedFile.base64,
					extension: extension,
					name: fileName,
				};

				postMedia(newFile).then(
					(_result: any) => {
						if (index === files.length - 1) {
							setLoading(false);
							console.log("result from postmedia", _result);
							callback(_result, newFile);
							inputRef.current.value = "";
						}
					},
					(_error) => {
						console.log("error from postmedia", _error);
						callback(_error, newFile);
						inputRef.current.value = "";
					}
				);
			}
		});
	};
	// triggers when file is selected with click
	const handleChange = function (e: any) {
		e.preventDefault();
		if (e.target.files && e.target.files[0]) {
			// at least one file has been selected so do something
			handleFiles(e.target.files);
		}
	};

	// triggers the input when the button is clicked
	const onButtonClick = () => {
		inputRef?.current.click();
	};

	const processFile = (fileToConvert: any) => {
		setLoading(true);
		return new Promise((resolve, reject) => {
			const newFileEvent = {
				name: fileToConvert.name,
				size: fileToConvert.size,
				uri: fileToConvert.name,
				type: fileToConvert.type,
				base64: "",
				path: "",
			};
			const reader = new FileReader();
			reader.readAsDataURL(fileToConvert);

			reader.onload = function () {
				let base64 = reader?.result?.toString() || "";
				base64 = base64.split("base64,")[1];
				newFileEvent.base64 = base64;

				const img = new Image();
				let formattedBase64 = reader.result as string;
				img.src = formattedBase64;
				img.onload = function () {
					// Définissez la taille souhaitée de l'image redimensionnée
					const MAX_WIDTH = 1200;
					const MAX_HEIGHT = 1200;
					let width = img.width;
					let height = img.height;
					if (width > height) {
						if (width > MAX_WIDTH) {
							height *= MAX_WIDTH / width;
							width = MAX_WIDTH;
						}
					} else {
						if (height > MAX_HEIGHT) {
							width *= MAX_HEIGHT / height;
							height = MAX_HEIGHT;
						}
					}

					const canvas = document.createElement("canvas");
					canvas.width = width;
					canvas.height = height;
					const ctx = canvas.getContext("2d");
					ctx?.drawImage(img, 0, 0, width, height);

					const dataUrl = canvas.toDataURL("image/jpeg");
					newFileEvent.base64 = dataUrl.split("base64,")[1];
					newFileEvent.path = URL.createObjectURL(fileToConvert);
					resolve(newFileEvent);
				};
			};
			reader.onerror = function (error) {
				console.log("Error: ", error);
				reject(error);
			};
		});
	};

	return (
		<form
			id="form-file-upload"
			onDragEnter={handleDrag}
			onSubmit={(e) => e.preventDefault()}
		>
			<input
				ref={inputRef}
				type="file"
				id="input-file-upload"
				multiple={true}
				onChange={handleChange}
			/>
			<label
				id="label-file-upload"
				htmlFor="input-file-upload"
				className={dragActive ? "drag-active" : ""}
			>
				{loading ? (
					<CircularProgress />
				) : (
					<div>
						<p>Glissez et déposez vos fichiers ici ou</p>
						<button className="upload-button" onClick={onButtonClick}>
							Envoyer un fichier
						</button>
					</div>
				)}
			</label>
			{dragActive && (
				<div
					id="drag-file-element"
					onDragEnter={handleDrag}
					onDragLeave={handleDrag}
					onDragOver={handleDrag}
					onDrop={handleDrop}
				></div>
			)}
		</form>
	);
};

export default DropUploader;
