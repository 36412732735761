import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { store } from "./Redux/store";

import App from "./App";
import "./Styles/App.css";

import { ThemeProvider } from "@mui/material/styles";
import theme from "./Styles/Theme";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<Provider store={store}>
		<Helmet>
			<meta charSet="utf-8" />
			<title>Grignoux - Admin</title>
			<meta name="robots" content="noindex,nofollow" />
		</Helmet>
		<ThemeProvider theme={theme}>
			<App />
		</ThemeProvider>
	</Provider>
);
