/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

/** Modules  */
import Notifications from "Components/Parts/Notifications";

/** Redux */
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { getUserId } from "Redux/Reducers/User/UserSlice";

/** Images */
const logo = require("Assets/Images/logo/logo.png");

function Dashboard() {
	const { t } = useTranslation();
	const currentUserId = useAppSelector(getUserId);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (currentUserId) {
		}
	}, [currentUserId, dispatch]);

	return (
		<div className="Page dashboard">
			<header>
				<div className="pageName">
					<img className="logo" src={logo} alt="Sayto" />
				</div>
				<Notifications />
			</header>

			<div className="content">
				<div className="row"></div>
			</div>
		</div>
	);
}

export default Dashboard;
