import React from "react";
import { Link } from "react-router-dom";

import Tooltip from "@mui/material/Tooltip";

import { useAppSelector } from "Redux/hooks";
import { getUser } from "Redux/Reducers/User/UserSlice";

import MovieIcon from "@mui/icons-material/Movie";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import PeopleIcon from "@mui/icons-material/People";
import TvIcon from "@mui/icons-material/Tv";
import PermMediaIcon from '@mui/icons-material/PermMedia';
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

import Logo from "Assets/Images/logo/logo.png";

function Navigation(props: { current: string | undefined }) {
	const user = useAppSelector(getUser);

	return (
		<div className={"navigation"}>
			<img className="logo" src={Logo} />
			<div className={"menu"}>
				<Link
					to={props.current === "films" ? "" : "/films"}
					className={props.current === "films" ? "active" : ""}
				>
					<MovieIcon />

					<Tooltip title="Films">
						<span>Films</span>
					</Tooltip>
				</Link>
				<Link
					to={props.current === "seances" ? "" : "/seances"}
					className={props.current === "seances" ? "active" : ""}
				>
					<ConfirmationNumberIcon />

					<Tooltip title="Séances">
						<span>Séances</span>
					</Tooltip>
				</Link>
				<Link
					to={props.current === "utilisateurs" ? "" : "/utilisateurs"}
					className={props.current === "utilisateurs" ? "active" : ""}
				>
					<PeopleIcon />

					<Tooltip title="Utilisateurs">
						<span>Utilisateurs</span>
					</Tooltip>
				</Link>

				<Link
					to={props.current === "scan" ? "" : "/scan"}
					className={props.current === "scan" ? "active" : ""}
				>
					<QrCodeScannerIcon />

					<Tooltip title="Vérificatino des places anniversaire">
						<span>Scan</span>
					</Tooltip>
				</Link>

				<Link
					to={props.current === "ecrans" ? "" : "/ecrans"}
					className={props.current === "ecrans" ? "active" : ""}
				>
					<TvIcon />

					<Tooltip title="Gestion des écrans">
						<span>Écrans</span>
					</Tooltip>
				</Link>

				<Link
					to={props.current === "medias" ? "" : "/medias"}
					className={props.current === "medias" ? "active subMenu" : "subMenu"}
				>
					<PermMediaIcon />

					<Tooltip title="Gestion des images utilisables dans les écrans">
						<span>Médiathèque</span>
					</Tooltip>
				</Link>

				{/*}
				<Link
					to="/rapports"
					className={props.current === "rapports" ? "active" : ""}
				>
					<LeaderboardOutlinedIcon />

					<Tooltip title="Rapports">
						<span>Rapports</span>
					</Tooltip>
				</Link>
	
				<Link
					to="/parametres"
					className={props.current === "parametres" ? "active" : ""}
				>
					<SettingsOutlinedIcon />
					<Tooltip title="Paramètres de l'application">
						<span>Paramètres</span>
					</Tooltip>
				</Link>
				{/*
				<Link
					to="/account"
					className={props.current === "account" ? "active" : ""}
				>
					<Tooltip title="Gestion de votre profil et de votre abonnement">
						<AccountCircleOutlinedIcon />
					</Tooltip>
				</Link>
			*/}
			</div>
		</div>
	);
}

export default Navigation;
