import { createTheme } from "@mui/material/styles";
import { frFR } from "@mui/x-date-pickers/locales";
import { frFR as corefrFR } from "@mui/material/locale";

import type {} from "@mui/lab/themeAugmentation";

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const theme = createTheme(
  {
    status: {
      danger: "#e53e3e",
    },

    palette: {
      primary: {
        main: "#008683",
      },
      secondary: {
        main: "#008683",
      },
      error: {
        main: "#99201c",
      },
      warning: {
        main: "#fccd87",
      },
      info: {
        main: "#7bb9b3",
      },
      success: {
        main: "#a9ce7d",
      },
    },
  },
  frFR,
  corefrFR
);

export default theme;
