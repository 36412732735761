/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from "react";
import {useParams, useNavigate} from "react-router-dom";

import {getSession} from "Api/Controllers/SessionsController";

function Session_edit() {
	const navigate = useNavigate();
	const {id} = useParams();
	const sessionId = id ? parseInt(id) : 0;

	const [session, setSession] = useState({});

	useEffect(() => {
		// Fetch the session from the API
		getSession(sessionId).then((result: any) => {
			//console.log("Session", result);
		});
	}, [id]);

	return (
		<div className="Page dashboard">
			<div className="container">
				<span>{id}</span>
			</div>
		</div>
	);
}

export default Session_edit;
