import {} from "Api/Controllers/CategoriesController";

import { putUser } from "Api/Controllers/UserController";
import { getAdmin } from "Api/Controllers/AdminController";

const AppMiddleware = (store: any) => (next: Function) => (action: any) => {
	const returnAction = { ...action };
	const globalState = store.getState();

	switch (action.type) {
		/** Account */

		default:
			next(action);
			break;
	}
};

export default AppMiddleware;
