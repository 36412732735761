/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Select from "react-select";

import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import TextField from "@mui/material/TextField";

import TvIcon from "@mui/icons-material/Tv";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";

/** Modules  */
import columnsDefinition from "./Columns";

/** Components */

import Table from "Components/Parts/Table";

/** Redux */
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { getUserId, getUser } from "Redux/Reducers/User/UserSlice";
import { setError } from "Redux/Reducers/App/AppSlice";

/** APi */

import {
	getScreens,
	postScreen,
	deleteScreen,
	updateScreen,
} from "Api/Controllers/ScreensController";
import { getCinemas } from "Api/Controllers/CategoriesController";

const places = [
	{ value: 1, label: "le Parc" },
	{ value: 2, label: "Churchill" },
	{ value: 3, label: "Sauvenière" },
	{ value: 4, label: "Caméo" },
];

function RenderAction(
	item: { props: any },
	column: any,
	pagination: any,
	navigate: Function,
	callback: Function
) {
	const screen = item.props;

	const openDetails = () => {
		navigate("/ecrans/" + screen.id);
	};

	return (
		<>
			<a href={"/show/" + screen.id} target="_blank" className="action">
				<VisibilityIcon />
			</a>
			<button onClick={openDetails} className="action">
				<EditIcon />
			</button>
			<button
				onClick={() => {
					callback(screen, "delete");
				}}
				className="action delete"
			>
				<DeleteForeverIcon />
			</button>
		</>
	);
}

function RenderStatus(
	item: { props: any },
	column: any,
	pagination: any,
	navigate: Function,
	callback: Function
) {
	const values = [
		{ value: "active", label: "En ligne" },
		{
			value: "offline",
			label: "Désactivé",
		},
		{ value: "only_pictures", label: "Uniquement la galerie" },
		{ value: "only_sessions", label: "Uniquement les séances" },
	];

	const screen = item.props;

	const handleUpdate = (element: any) => {
		screen.status = element.value;
		callback(screen, "update");
	};

	const defaultValue = values.filter((val: any) => {
		return val.value === screen.status;
	});

	return (
		<div className="screen_status">
			<div className="icon">
				<TvIcon />
				<span className={"bullet_status " + screen?.status}></span>
			</div>

			<Select
				aria-label="Statut de l'écran"
				placeholder="Statut de l'écran"
				className="select"
				value={defaultValue}
				onChange={(element: any) => {
					handleUpdate(element);
				}}
				options={values}
			/>
		</div>
	);
}

function RenderCinema(
	item: { props: any },
	column: any,
	pagination: any,
	navigate: Function,
	callback: Function
) {
	const values = [
		{ value: 1, label: "le Parc" },
		{ value: 2, label: "Churchill" },
		{ value: 3, label: "Sauvenière" },
		{ value: 4, label: "Caméo" },
	];

	const screen = item.props;

	const handleUpdate = (element: any) => {
		screen.id_place = element.value;
		callback(screen, "update");
	};

	const defaultValue = values.filter((val: any) => {
		return val.value === parseInt(screen.id_place);
	});

	return (
		<div className="screen_status">
			<Select
				aria-label="Cinéma"
				placeholder="Cinéma"
				className="select"
				value={defaultValue}
				onChange={(element: any) => {
					handleUpdate(element);
				}}
				options={values}
			/>
		</div>
	);
}

function Screens() {
	const { t } = useTranslation();
	const currentUser: any = useAppSelector(getUser);
	const currentUserId = useAppSelector(getUserId);

	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(true);

	const [screens, setScreens] = useState<any[]>([]);

	const [searchedValue, setSearchedValue] = useState("");
	const [filter, setFilter] = useState(false);
	const [columns, setColumns] = useState(columnsDefinition);

	const [currentPage, setCurrentPage] = useState(1);
	const [pagination, setPagination] = useState({} as any);
	const [cinemas, setCinemas] = useState<any[]>([]);

	const [openAddModal, setOpenAddModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	const [newScreen, setNewScreen] = useState<any>({});

	const [loadingAdd, setLoadingAdd] = useState(false);
	const [itemToDelete, setItemToDelete] = useState<any>({});

	const refreshScreens = () => {
		setTimeout(() => {
			getScreens({})
				.then((res: any) => {
					getCinemas().then((cinema_res: any) => {
						const cinemas = cinema_res.data;
						setCinemas(cinemas);

						const formattedScreens = res.map((screen: any) => {
							return {
								name: screen.name,
								cinema: RenderCinema,
								status: RenderStatus,
								action: RenderAction,
								props: screen,
							};
						});

						setScreens(formattedScreens);
						setLoading(false);
					});
				})
				.catch((err: any) => {
					dispatch(setError(err));
					setLoading(false);
				});
		}, 100);
	};
	const addScreen = () => {
		setLoadingAdd(true);
		postScreen(newScreen).then(() => {
			refreshScreens();
			setLoadingAdd(false);
			setOpenAddModal(false);
		});
	};

	const handleItemModification = (item: any, action: string) => {
		if (action === "delete") {
			setOpenDeleteModal(true);
			setItemToDelete(item);
		} else if (action === "update") {
			updateScreen(item).then((res) => {
				refreshScreens();
			});
		}
	};

	const confirmDelete = () => {
		deleteScreen(itemToDelete.id).then(() => {
			refreshScreens();
			setOpenDeleteModal(false);
			setItemToDelete({});
		});
	};

	useEffect(() => {
		setColumns(columnsDefinition);
	}, []);
	useEffect(() => {
		if (currentUserId) {
		}
	}, [currentUserId, dispatch]);

	useEffect(refreshScreens, [dispatch]);

	return (
		<div className="Page dashboard">
			<Dialog
				className="delete_screen_modal"
				open={openDeleteModal}
				onClose={() => {
					setOpenDeleteModal(false);
				}}
			>
				<DialogTitle className={"head"}>
					<span>Supprimer un écran</span>
					<TvIcon className="head_icon" />
				</DialogTitle>
				<div className="modalContent">
					<strong>Voulez-vous supprimer l'écran {itemToDelete.name} ?</strong>
				</div>
				<DialogActions>
					<Button variant="text" onClick={() => setOpenDeleteModal(false)}>
						Annuler
					</Button>
					<LoadingButton
						variant="contained"
						color="error"
						onClick={confirmDelete}
					>
						Supprimer
					</LoadingButton>
				</DialogActions>
			</Dialog>
			<Dialog
				className="add_screen_modal"
				open={openAddModal}
				onClose={() => setOpenAddModal(false)}
			>
				<DialogTitle className={"head"}>
					<span>Ajouté un écran</span>
					<TvIcon className="head_icon" />
				</DialogTitle>
				<div className="modalContent">
					<TextField
						className="modal_input"
						label="Nom de l'écran"
						onChange={(e) => {
							const newScreenElement = { ...newScreen };
							newScreenElement.name = e.target.value;
							setNewScreen(newScreenElement);
						}}
					/>

					<Select
						aria-label="Statut de l'écran"
						placeholder="Statut de l'écran"
						className="modal_input"
						onChange={(element: any) => {
							const newScreenElement = { ...newScreen };
							newScreenElement.status = element.value;
							setNewScreen(newScreenElement);
						}}
						options={[
							{ value: "active", label: "Ecran actif" },
							{
								value: "offline",
								label: "Ecran inactif ( affichera le logo des grignoux)",
							},
							{ value: "only_pictures", label: "Uniquement la galerie" },
							{ value: "only_sessions", label: "Uniquement les séances" },
						]}
					/>

					<Select
						aria-label="Cinéma"
						placeholder="Cinéma"
						className="modal_input"
						options={places}
						onChange={(element: any) => {
							const newScreenElement = { ...newScreen };
							newScreenElement.id_place = element.value;
							setNewScreen(newScreenElement);
						}}
					/>
				</div>
				<DialogActions>
					<LoadingButton
						loading={loadingAdd}
						variant="contained"
						color="primary"
						disabled={
							!newScreen.name || !newScreen.id_place || !newScreen.status
						}
						onClick={addScreen}
					>
						Ajouter
					</LoadingButton>
				</DialogActions>
			</Dialog>
			<header>
				<div className="pageName">
					<strong>Gestion des écrans</strong>
				</div>
				<LoadingButton
					className="saveButton"
					onClick={() => {
						setOpenAddModal(true);
					}}
					loading={false}
					variant="contained"
					endIcon={<AddIcon />}
				>
					Ajouter
				</LoadingButton>
			</header>

			<div className="content">
				<Table
					datas={screens}
					columns={columns}
					loading={loading}
					callback={handleItemModification}
				/>
			</div>
		</div>
	);
}

export default Screens;
