const RenderActions = (data: any) => {
	return <div></div>;
};

const columns = [
	{
		key: "id",
		label: "ID",
		sortable: true,
		show: true,
		min: true,
		size: 1,
		center: true,
	},
	{
		key: "id_gticket",
		label: "ID EXT",
		sortable: true,
		size: 1,
	},
	{
		key: "title",
		label: "Titre",
		sortable: true,
		size: 6,
	},
	{
		key: "date_sortie",
		label: "Date de sortie",
		sortable: true,
		size: 2,
		center: true,

	},
	{
		key: "alaune",
		label: "À la une",
		sortable: true,
		min: true,
		size: 1,
		center: true,
	},
	{
		key: "archived",
		label: "Archivé",
		sortable: true,
		min: true,
		size: 1,
		center: true,
	},
	{
		key: "eltn",
		label: "ELTN",
		sortable: true,
		min: true,
		size: 1,
		center: true,
	},
	{
		key: "soon",
		label: "Proch.",
		sortable: true,
		min: true,
		size: 1,
		center: true,
	},
	{
		key: "published",
		label: "Publié",
		sortable: true,
		min: true,
		size: 1,
		center: true,
	},
	{
		key: "action",
		label: "Actions",
		sortable: false,
		custom: true,
		render: RenderActions,
		size: 2,
	},
];

export default columns;
