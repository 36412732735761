/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";

/* Icons */
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { resetServerContext } from "react-beautiful-dnd";

import DropUploader from "Components/Parts/DropUploader";

import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { getMedias, deleteMedia, editMedia } from "Api/Controllers/ScreensController";

import MediaDetails from "./Media_details";

/** Components */
import Table from "Components/Parts/Table";
import columnsDefinition from "./ColumnsMedia";


resetServerContext();

function RenderPicture(
	item: { props: any },
	_column: any,
	_pagination: any,
	_navigate: Function,
	callback: Function
) {
	const picture = item?.props;

	return (
		<div className={"picture_table"} onClick={() => callback(picture, "edit")}>
			<img src={picture?.path} alt={picture?.name} />
			<span>{picture?.name}</span>
		</div >
	)
}

function RenderAction(
	item: { props: any },
	column: any,
	pagination: any,
	navigate: Function,
	callback: Function
) {
	const media = item.props;

	return (
		<>
			<button onClick={() => {
				callback(media, "edit");
			}} className="action">
				<VisibilityIcon />
			</button>
			<button
				onClick={() => {
					callback(media, "delete");
				}}
				className="action delete"
			>
				<DeleteForeverIcon />
			</button>
		</>
	);
}

function MediasList() {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [medias, setMedias] = React.useState<any[]>([]);

	const [showDeleteModal, setShowDeleteModal] = React.useState(false);
	const [mediaToDelete, setMediaToDelete] = React.useState<any>({});

	const [search, setSearch] = React.useState("");

	const [selectedMedia, setSelectedMedia] = React.useState<any>({});
	const [openDetails, setOpenDetails] = React.useState(false);
	const [errorModal, setErrorModal] = React.useState(false);
	const [columns, setColumns] = useState(columnsDefinition);

	const [pagination, setPagination] = useState({} as any);

	const [filters, setFilters] = useState({
		search: search,
		page: 1,
		order: "id",
		orderType: "ASC",
	});

	const refreshMedias = () => {
		setLoading(true);
		getMedias(filters).then(
			(response: any) => {

				const currentPagination = {
					page: response?.currentpage,
					total: response?.nbpage,
				};

				setPagination(currentPagination);

				const formattedMedias = response?.medias.map((screen: any) => {
					return {
						name: RenderPicture,
						upload_time: screen.upload_time,
						bookmark: screen.bookmark,
						action: RenderAction,
						props: screen,
					};
				});

				setMedias(formattedMedias);
				setLoading(false);
			}
		);
	};

	const confirmMediaDelete = () => {
		deleteMedia(mediaToDelete.id).then((response: any) => {
			setMediaToDelete({});
			setShowDeleteModal(false);
			refreshMedias();
		});
	};

	React.useEffect(() => {
		refreshMedias();
	}, [filters]);

	React.useEffect(() => {
		setFilters({ ...filters, search, page: 1 })
		setPagination({ ...pagination, page: 1 })
	}, [search]);

	React.useEffect(() => {
		setColumns(columnsDefinition);
	}, []);

	return (
		<div className="Page dashboard">
			<header>
				<div className="pageName">
					<Button onClick={() => navigate("/ecrans")}>
						<KeyboardBackspaceIcon />
					</Button>
					<strong>Médiathèque</strong>
				</div>
			</header>
			<MediaDetails
				image={selectedMedia}
				open={openDetails}
				onClose={(datasImage: any) => {
					setSelectedMedia(null);
					setOpenDetails(false);

					const newMedias = [...medias];
					newMedias.forEach((media: any, index: number) => {
						if (media.id === datasImage.id) {
							newMedias[index].screens = datasImage.screens;
						}
					});
					setMedias(newMedias);
				}}
			/>

			<div className="content">

				<div className="screen_pictures">
					<DropUploader
						callback={(e: any, _file: any) => {
							if (
								e?.message &&
								(e.message === "Image name exists" ||
									e.message === "Media name already exists")
							) {
								setErrorModal(true);
								setSearch(_file.name);
							} else {
								refreshMedias();
							}
						}}
					/>
					<div>
						<Dialog
							sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
							maxWidth="xs"
							open={errorModal}
						>
							<DialogTitle>Une image existe déjà</DialogTitle>
							<DialogContent dividers>
								<span>
									Une image nommée : <strong>{search}</strong> existe déjà.
								</span>
								<br />
								<span>
									Vérifiez le nom de votre image avant de l'envoyer ou supprimez
									l'image existante.
								</span>
							</DialogContent>
							<DialogActions>
								<Button
									autoFocus
									onClick={() => {
										setErrorModal(false);
									}}
								>
									Afficher l'image existante
								</Button>
							</DialogActions>
						</Dialog>
						<Dialog
							sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
							maxWidth="xs"
							open={showDeleteModal}
						>
							<DialogTitle>Confirmation de suppression</DialogTitle>
							<DialogContent dividers>
								<span>Voulez-vous supprimer le média </span>
								<strong>{mediaToDelete.name}</strong>
								<span>?</span>

								{mediaToDelete.pictures > 0 && (
									<div
										style={{
											padding: "10px",
											backgroundColor: "#ff6f6f",
											marginTop: "10px",
											color: "#FFF",
										}}
									>
										<span>Attention, ce média est utilisé </span>
										<strong>{mediaToDelete.pictures} fois</strong>
										<br />
										<br />
										<span style={{ fontStyle: "italic", marginTop: 5 }}>
											Si vous supprimer ce média, toutes les images liées seront
											également supprimées.
										</span>
									</div>
								)}
							</DialogContent>
							<DialogActions>
								<Button
									autoFocus
									onClick={() => {
										setShowDeleteModal(false);
									}}
								>
									Annuler
								</Button>
								<Button color="error" onClick={confirmMediaDelete}>
									Supprimer
								</Button>
							</DialogActions>
						</Dialog>

						<div className="search">
							<input
								className="searchInput"
								type="text"
								value={search || ""}
								onChange={(e) => setSearch(e.target.value)}
								placeholder="Rechercher dans les médias"
							/>
						</div>
						<ul className="medias">
							<Table
								datas={medias}
								columns={columns}
								loading={loading}
								pagination={pagination}
								onPageChange={(page: number) => {
									const newFilters = {
										...filters,
										page: page,
									};
									setFilters(newFilters);
								}}
								onSort={(column: string, order: string) => {
									const newFilters = {
										...filters,
										order: column,
										orderType: order,
									};
									setFilters(newFilters);
								}}
								callback={(picture: any, action: string) => {

									if (action === "edit") {
										setSelectedMedia(picture);
										setOpenDetails(true);
									}

									if (action === "delete") {
										setMediaToDelete(picture);
										setShowDeleteModal(true);
									}
								}}
								onEdit={(item: any, key: string) => {
									const editedItem = { id: item.id, [key]: item[key] };
									editMedia(editedItem).then((response: any) => {
										console.log("response", response);
									});
								}}
							/>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MediasList;
