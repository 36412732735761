import abstractApiObject from "./abstractApiObject";

class categoryApiObject extends abstractApiObject {
  constructor() {
    super();
    this.controller = "category";
  }

  getCinemas() {
		const params = {
			url: this.controller + "/cinema/",
			method: "GET",
			body: {},
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}


}

export default categoryApiObject;
