import { defaultReturnObject, ApiReturnDatas } from "Types/Api";

import userApiObject from "Api/Models/User";
const userApi = new userApiObject();

export const getUsers = (datas: any) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		datas.limit = 40;
		userApi.getList(datas).then(
			(result: any) => {
				returnDatas.status = "success";
				returnDatas.message = "request_success";
				returnDatas.data = result;
				resolve(returnDatas);
			},
			(error) => {
				returnDatas.message = "request_failure";
				reject(returnDatas);
			}
		);
	});
};

export const getUser = (id: number) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		userApi.getOne(id).then(
			(result: any) => {
				returnDatas.status = "success";
				returnDatas.message = "request_success";
				returnDatas.data = result;
				resolve(returnDatas);
			},
			(error) => {
				console.log(error);
				returnDatas.message = "request_failure";
				reject(returnDatas);
			}
		);
	});
};

export const putUser = (userToSave: any) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	const userClone = structuredClone(userToSave);

	return new Promise((resolve, reject) => {
		userApi.put(userClone).then(
			(result: any) => {
				returnDatas.status = "success";
				returnDatas.message = "";
				resolve(returnDatas);
			},
			(error: any) => {
				reject(returnDatas);
			}
		);
	});
};

export const deleteUser = (idUser: number) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		userApi.delete(idUser).then(
			(result: any) => {
				returnDatas.status = "success";
				returnDatas.message = "";
				resolve(returnDatas);
			},
			(error: any) => {
				reject(returnDatas);
			}
		);
	});
};

export const postPassword = (password: any) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		userApi.postPassword(password).then(
			(result: any) => {
				if (result.status === "ok") {
					returnDatas.status = "success";
					returnDatas.message = "";
					returnDatas.data = result;
					resolve(returnDatas);
				} else {
					reject(returnDatas);
				}
			},
			(error: any) => {
				reject(returnDatas);
			}
		);
	});
};

export const postLogin = (login: string, password: string) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		userApi.postLogin(login, password).then(
			(result: any) => {
				if (result.status === "ok") {
					returnDatas.status = "success";
					returnDatas.data = result;
					returnDatas.message = "";
					resolve(returnDatas);
				} else {
					returnDatas.message = "login_failed";
					reject(returnDatas);
				}
			},
			(error) => {
				console.log(error);
				reject(error);
			}
		);
	});
};

export const postRegister = (
	email: string,
	password: string,
	id_country: string
) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		userApi.postRegister(email, password, id_country).then(
			(result: any) => {
				if (result.status === "ok") {
					returnDatas.status = "success";
					returnDatas.data = result;
					returnDatas.message = "";
					resolve(returnDatas);
				} else {
					returnDatas.message = "register_failed";
					reject(returnDatas);
				}
			},
			(error) => {
				console.log(error);
				reject(error);
			}
		);
	});
};

export const postCredentials = (provider: string, credentials: string) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		userApi.postCredentials(provider, credentials).then(
			(result: any) => {
				if (result.status === "ok") {
					returnDatas.status = "success";
					returnDatas.data = result;
					returnDatas.message = "";
					resolve(returnDatas);
				} else {
					returnDatas.message = "credentials_failed";
					reject(returnDatas);
				}
			},
			(error: any) => {
				console.log(error);
				reject(error);
			}
		);
	});
};

export const checkCode = (code: string) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		userApi.checkCode(code).then(
			(result: any) => {
				if (result.status === "ok") {
					returnDatas.status = "success";
					returnDatas.data = result.data;
					returnDatas.message = "";
					resolve(returnDatas);
				} else {
					returnDatas.message = result.msg;
					reject(returnDatas);
				}
			},
			(error: any) => {
				console.log(error);
				reject(error);
			}
		);
	});
};


export const consumeCode = (code: string) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		userApi.consumeCode(code).then(
			(result: any) => {
				if (result.status === "ok") {
					returnDatas.status = "success";
					returnDatas.data = result.data;
					returnDatas.message = "";
					resolve(returnDatas);
				} else {
					returnDatas.message = result.msg;
					reject(returnDatas);
				}
			},
			(error: any) => {
				console.log(error);
				reject(error);
			}
		);
	});
};
