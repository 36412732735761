import {ApiType, ApiHeaderType} from "Types/Api";
import {getItem} from "Utils/Storage";

import Config from "Config";

const token = getItem("token");

class abstractApiObject {
	controller!: string;
	apiUrl: string;

	constructor() {
		this.apiUrl = Config.apiUrl;
	}

	call(params: ApiType) {
		let method = params.method ? params.method : "GET";
		method = method === "POST_FILE" ? "POST" : method;
		method = method.toUpperCase();
		return new Promise((resolve, reject) => {
			const init: ApiHeaderType = {
				method: method,
				headers: {},
			};

			if (params.method === "POST") {
				init.headers = {
					Accept: "application/json",
					"Content-Type": "application/json",
				};

				init.body = JSON.stringify(params.body);
			}

			if (params.method === "POST_FILE") {
				init.headers = {};
				const formData = new FormData();
				formData.append("file", params.body);
				init.body = formData;
			}

			if (params.secured) {
				init.headers.Authorization = "Bearer " + token;
			}

			const url = this.apiUrl + params.url;

			try {
				fetch(url, init)
					.then(
						(response: any) => {
							if (response.status === 401) {
								reject({
									type: "wrong_credentials",
									message: "API.ERRORS.wrong_credentials",
								});
							}
							if (response.status === 422) {
								response.json().then((datas: any) => {
									reject({
										type: "error_import",
										message:
											datas.message && datas.message !== ""
												? datas.message
												: "API.ERRORS.error_import",
										errors: datas.errors,
									});
								});
							}
							if (response.status !== 200 && response.status !== 201) {
								response.json().then((datas: any) => {
									reject({
										type: "response_status",
										message:
											datas.message && datas.message !== ""
												? datas.message
												: "API.ERRORS.wrong_status",
									});
								});
							} else {
								try {
									response.json().then(
										(datas) => {
											resolve(datas);
										},
										() => {
											reject({
												type: "response_datas",
												message: "API.ERRORS.JSON_error_datas",
											});
										}
									);
								} catch (e) {
									reject({
										type: "response_parsing",
										message: "API.ERRORS.JSON_fail_parsing",
									});
								}
							}
						},
						() => {
							reject({
								type: "fetch_error",
								message: "API.ERRORS.fetch_error",
							});
						}
					)
					.catch(() => {
						reject({
							type: "fetch_error",
							message: "API.ERRORS.fetch_error",
						});
					});
			} catch (e) {
				reject({
					type: "response_error",
					message: "API.ERRORS.catch_fetch_error",
				});
			}
		});
	}

	get() {
		const params = {
			url: this.controller,
			method: "GET",
			body: {},
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	getOne(id: number | string | undefined) {
		const params = {
			url: this.controller + "/" + id,
			method: "GET",
			body: {},
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	getList(filters: any) {
		let url = this.controller + "?1=1";

		for (let key in filters) {
			url += "&" + key + "=" + filters[key];
		}

		const params = {
			url: url,
			method: "GET",
			body: {},
			secured: true,
		};

		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result: any) => {
					if (result) {
						resolve(result);
					} else {
						reject(result);
					}
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	post(datas: any) {
		return new Promise((resolve, reject) => {
			const params = {
				url: this.controller,
				method: "POST",
				body: datas,
				secured: true,
			};

			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	put(datas: any) {
		const params = {
			url: this.controller + "/" + datas.id,
			method: "POST",
			body: datas,
			secured: true,
		};

		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	delete(id: number) {
		return new Promise((resolve, reject) => {
			const params = {
				url: this.controller + "/" + id,
				method: "delete",
				body: {},
				secured: true,
			};

			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}
}
export default abstractApiObject;
