/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { styled } from "@mui/material/styles";

import LoadingButton from "@mui/lab/LoadingButton";

import { Button, Dialog, DialogTitle } from "@mui/material";

/** Modules  */
import Search from "Components/Parts/Search";
import columnsDefinition from "./Columns";

/** Components */
import SessionAdd from "./Session_add";
import Table from "Components/Parts/Table";

/** API */

import {
	getSessions,
	postImport,
	deleteSession,
	postSession,
} from "Api/Controllers/SessionsController";

/** Utils */

import { getErrorLabel } from "Utils/errors";
import { setError } from "Redux/Reducers/App/AppSlice";

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});

function RenderAction(props: any) {
	const { item, deleteAction, navigate } = props;

	return (
		<div className="actions">
			<Button className="error" onClick={() => deleteAction(item)}>
				<DeleteForeverIcon />
			</Button>
		</div>
	);
}

function Sessions() {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [sessionsList, setSessionsList] = useState([] as any[]);
	const [searchedValue, setSearchedValue] = useState("");
	const [filters, setFilters] = useState({
		search: "",
		page: 1,
	} as any);
	const [columns, setColumns] = useState(columnsDefinition);

	const [pagination, setPagination] = useState({} as any);
	const [openImport, setOpenImport] = useState(false);
	const [loadImport, setLoadImport] = useState(false);
	const [importError, setImportError] = useState("");
	const [importErrorList, setImportErrorList] = useState({} as any);
	const [importSuccess, setImportSuccess] = useState("");

	const [openAddSession, setOpenAddSession] = useState(false);

	const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
	const [sessionToDelete, setDeleteSession] = useState({
		id: 0,
		title: "",
		date: "",
		hour: "",
	} as any);

	useEffect(() => {
		setColumns(columnsDefinition);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const GetSessionsList = async () => {
		setLoading(true);
		const response: any = await getSessions(filters);

		const datas: any = [];
		setLoading(false);

		const currentPagination = {
			page: response.data.currentpage,
			total: response.data.nbpage,
		};

		setPagination(currentPagination);

		const deleteAction = (session: any) => {
			setDeleteSession(session);
			setOpenConfirmDelete(true);
		};

		if (response.data.session.length > 0) {
			for (const session of response.data.session) {
				datas.push({
					id: session.id,
					title: session.title,
					datesession: session.datesession,
					hour: session.hour,
					perf_id: session.perf_id,
					auditorium_id: session.auditorium_id,
					auditorium_name: session.auditorium_name,
					cinema: session.cinema_name,
					id_gticket: session.id_gticket,
					duration: session.duration,
					archived: session.archived,
					complete: session.complete,
					derniereseance: session.derniereseance,
					actions: (item: any) => {
						return RenderAction({
							item,
							deleteAction,
							navigate,
						});
					},
					props: session,
				});
			}
			setSessionsList(datas);
		}
	};

	useEffect(() => {
		GetSessionsList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	const proceedImportErrors = (errors: any) => {
		let errorsArray = {} as any;
		Object.keys(errors).forEach((key: any) => {
			if (errors[key].length > 0) {
				errorsArray[key] = {
					label: getErrorLabel(key),
					errors: errors[key],
				};
			}
			if (Object.values(errors[key]).length > 0) {
				errorsArray[key] = {
					label: getErrorLabel(key),
					errors: Object.values(errors[key]),
				};
			}
		});
		setImportErrorList(errorsArray);
	};
	const importSessions = (file: any) => {
		setLoadImport(true);
		setImportError("");
		setImportSuccess("");

		if (file.type !== "text/csv") {
			setImportError("Le fichier doit être au format CSV");
			setLoadImport(false);
			return;
		} else {
			postImport(file).then(
				(response: any) => {
					setImportSuccess(response.message);
					setLoadImport(false);
					document.getElementById("importFile")?.setAttribute("value", "");
				},
				(error: { type: string; message: string; errors?: any }) => {
					setLoadImport(false);
					error?.errors && proceedImportErrors(error.errors);
					setImportError(error.message);
					document.getElementById("importFile")?.setAttribute("value", "");
				}
			);
		}
	};

	const confirmDelete = () => {
		deleteSession(sessionToDelete?.id).then((response: any) => {
			GetSessionsList();
			setOpenConfirmDelete(false);
			setDeleteSession({ id: 0, title: "", date: "", hour: "" });
		});
	};
	return (
		<>
			<Dialog
				open={openImport}
				onClose={() => {
					setOpenImport(false);
					setLoadImport(false);
				}}
			>
				<DialogTitle className={"modalHeader"}>
					<span className="title">Importer des séances</span>
				</DialogTitle>
				<div className="modalContent">
					<span className="info">
						Sélectionner un fichier à envoyer (.csv uniquement)
					</span>
					<br />
					{loadImport && (
						<span className="loading message">Chargement en cours...</span>
					)}
					{importSuccess && (
						<span className="success message">{importSuccess}</span>
					)}
					{importError && Object.values(importErrorList).length === 0 && (
						<span className="error message ">{importError}</span>
					)}
					{importErrorList && Object.values(importErrorList).length > 0 && (
						<div className="error message list">
							{Object.values(importErrorList).map(
								(error: any, index: number) => (
									<div key={index}>
										<strong>{error.label}</strong>
										<ul>
											{error.errors.map((err: string, index: number) => (
												<li key={index}>{err}</li>
											))}
										</ul>
									</div>
								)
							)}
						</div>
					)}
					<LoadingButton
						loading={loadImport}
						component="label"
						variant="contained"
						loadingIndicator={<CircularProgress color="inherit" size={16} />}
						startIcon={<CloudUploadIcon />}
					>
						Importer
						<VisuallyHiddenInput
							accept=".csv"
							type="file"
							id="importFile"
							onChange={(file: any) => {
								setError("");
								setImportError("");
								setImportErrorList({});
								setImportSuccess("");
								if (file?.target?.files[0] === undefined) {
									setLoadImport(false);
									return;
								}
								setLoadImport(true);
								importSessions(file?.target?.files[0]);
							}}
						/>
					</LoadingButton>
				</div>
			</Dialog>
			<Dialog
				open={openConfirmDelete}
				onClose={() => {
					setOpenConfirmDelete(false);
					setDeleteSession({ id: 0, title: "", date: "", hour: "" });
				}}
			>
				<DialogTitle className={"modalHeader"}>
					<span className="title">Confirmer la suppression</span>
				</DialogTitle>
				<div className="modalContent modalDelete">
					<span>Voulez-vous supprimer la séance </span>
					<strong>
						#{sessionToDelete.id} {sessionToDelete.title} ?
					</strong>
					<br />
					<div className="row">
						<Button
							variant="outlined"
							color="primary"
							onClick={() => {
								setOpenConfirmDelete(false);
								setDeleteSession({ id: 0, title: "", date: "", hour: "" });
							}}
						>
							annuler
						</Button>
						<div className="spacer" />

						<Button variant="contained" color="error" onClick={confirmDelete}>
							Confirmer
						</Button>
					</div>
				</div>
			</Dialog>
			<Dialog
				className="modalForm"
				open={openAddSession}
				onClose={() => {
					setOpenAddSession(false);
				}}
			>
				<DialogTitle className={"modalHeader"}>
					<span className="title">Ajouter une séance</span>
				</DialogTitle>
				<div className="modalContent">
					<SessionAdd
						onCancel={() => {
							setOpenAddSession(false);
						}}
						onSave={() => {
							GetSessionsList();
						}}
					/>
				</div>
			</Dialog>
			<div className="Page listing">
				<header>
					<div className="pageName">
						<strong>Liste des séances</strong>
					</div>
					<Search
						label="Rechercher une séance"
						value={searchedValue}
						onChange={(value: string) => {
							setSearchedValue(value);
							setFilters({ ...filters, search: value, page: 1 });
						}}
					/>
					<div className="spacer" />
					<Button
						variant="contained"
						onClick={() => setOpenAddSession(true)}
						color="secondary"
					>
						Ajouter
					</Button>
					<div className="spacer" />
					<Button
						variant="contained"
						color="primary"
						onClick={() => setOpenImport(true)}
					>
						Importer
					</Button>
				</header>

				<div className="content">
					<div className="row">
						<Table
							datas={sessionsList}
							columns={columns}
							loading={loading}
							pagination={pagination}
							onPageChange={(page: number) =>
								setFilters({ ...filters, page: page })
							}
							onSort={(column: string, order: string) => {
								setFilters({ ...filters, sort: column, sort_direction: order });
							}}
							onEdit={(item: any, key: string) => {
								const editedItem = { id: item.id, [key]: item[key] };
								postSession(editedItem).then((response: any) => { });
							}}
						/>
					</div>
				</div>
			</div>
		</>
	);
}

export default Sessions;
