/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { Dialog, DialogTitle, List, ListItem } from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

/** Redux */
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { getUserId, getUser } from "Redux/Reducers/User/UserSlice";
import { setError } from "Redux/Reducers/App/AppSlice";

/** Api */
import { checkCode, consumeCode } from "Api/Controllers/UserController";
import { use } from "i18next";

/** Images */
const logo = require("Assets/Images/logo/logo.png");

function Scan() {
	const { t } = useTranslation();
	const currentUser: any = useAppSelector(getUser);
	const currentUserId = useAppSelector(getUserId);
	const [openCodeInfos, setOpenCodeInfos] = useState(false);
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(false);
	const [errorInCode, setErrorInCode] = useState(false);

	const [code, setCode] = useState("");

	const [data, setData] = useState({} as any);
	const [consumed, setConsumed] = useState(false);
	const [errorConsumed, setErrorConsumed] = useState(false);

	const check = () => {
		if (code) {
			setLoading(true);
			setConsumed(false);
			setErrorConsumed(false);
			checkCode(code).then(
				(result: any) => {
					setData(result.data);
					setOpenCodeInfos(true);
					setLoading(false);
					setErrorInCode(false);
				},
				(error) => {
					setData(error);
					setOpenCodeInfos(true);
					setErrorInCode(true);
					setLoading(false);
				}
			);
		}
	};

	const consume = () => {
		setLoading(true);
		consumeCode(code).then(
			(result: any) => {
				setLoading(false);
				setConsumed(true);
				setErrorConsumed(false);
			},
			(error) => {
				setConsumed(false);
				setLoading(false);
				setErrorConsumed(true);
			}
		);
	};

	return (
		<div className="Page dashboard">
			<Dialog open={openCodeInfos} onClose={() => setOpenCodeInfos(false)}>
				<DialogTitle
					className={errorInCode ? "error modalHeader" : "success modalHeader"}
				>
					<span>Code scanné</span>
					<strong>{code}</strong>
				</DialogTitle>
				<div className="modalContent">
					{!errorInCode ? (
						<>
							{consumed ? (
								<>
									<span className="validCode">La place a été validée.</span>
									<Button
										className="closeButton"
										variant="contained"
										onClick={() => setOpenCodeInfos(false)}
									>
										Fermer
									</Button>
								</>
							) : (
								<>
									<List sx={{ pt: 0 }}>
										<ListItem>
											<strong>Prénom : </strong>
											<span> </span>
											<span> {data?.user?.firstname}</span>
										</ListItem>
										<ListItem>
											<strong>Nom : </strong>
											<span> </span>
											<span> {data?.user?.name}</span>
										</ListItem>
										<ListItem>
											<strong>Date d'anniversaire : </strong>
											<span> </span>
											<span> {data?.user?.birthdate}</span>
										</ListItem>
										<div className="separator"></div>
										<ListItem className="modalItemCol">
											<strong>Place générée le</strong>
											<br />
											<span>{data?.creation_date}</span>
										</ListItem>
									</List>
									<LoadingButton
										className="closeButton"
										variant="contained"
										loading={loading}
										onClick={consume}
									>
										Valider la place
									</LoadingButton>
									<span className="errorIndicator">
										Une fois validée, la place ne sera plus disponible au scan.
									</span>
								</>
							)}
						</>
					) : (
						<>
							<span className="errorCode">Ce code est invalide.</span>
							<span className="errorIndicator">{data?.message}</span>
							<Button
								className="closeButton"
								variant="contained"
								onClick={() => setOpenCodeInfos(false)}
							>
								Fermer
							</Button>
						</>
					)}
				</div>
			</Dialog>
			<header>
				<div className="pageName">
					<strong>Scan - Vérification des places anniversaire</strong>
				</div>
			</header>

			<div className="content">
				<div className="row">
					<div className="scan">
						<QrCodeScannerIcon />
						<span>Scanner le QR Code</span>

						<div className="separator"></div>
						<span>ou</span>
						<span>Entrer le code manuellement</span>
						<TextField
							id="QrScan"
							label="Code unique de la place anniversaire (ex: 24FRD4955e8765)"
							variant="outlined"
							onChange={(e) => {
								setCode(e.target.value);
							}}
						/>
						<LoadingButton
							loading={loading}
							variant="contained"
							color="primary"
							onClick={check}
						>
							Vérifier
						</LoadingButton>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Scan;
