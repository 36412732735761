import abstractApiObject from "./abstractApiObject";

class sessionApiObject extends abstractApiObject {
	constructor() {
		super();
		this.controller = "session";
	}

	postImport(datas: any) {
		const params = {
			url: this.controller + "/import",
			method: "POST_FILE",
			body: datas,
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}
}

export default sessionApiObject;
