/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams,useNavigate } from "react-router-dom";

import config from "Config";

/** Backgrounds */

import background from "Assets/Images/backgrounds/login.png";
import logo from "Assets/Images/logo/logo.png";

/** Form components */
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";

/** Storage */
import { setItem, removeItem } from "Utils/Storage";

/** Redux */
import { useAppDispatch } from "Redux/hooks";
import { setError } from "Redux/Reducers/App/AppSlice";

/** Form components */

/** API */
import adminApiObject from "Api/Models/Admin";
const adminApi = new adminApiObject();

function Login() {
	const { id } = useParams();
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const [login, setLogin] = useState("");
	const [password, setPassword] = useState("");
	const [error, setLoginError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [pageLoading, setPageLoading] = useState(false);
	const [tokenHasbeenCheched, setTokenHasbeenCheched] = useState(false);

	const [errorMessage, setErrorMessage] = useState(
		t("ACCOUNT.FORM.ERRORS.field_check")
	);

	useEffect(() => {}, [tokenHasbeenCheched]);

	/** Attempt login */
	const attemptLogin = () => {
		setLoading(true);
		setLoginError(false);
		if (login === "" || password === "") {
			setLoginError(true);
			setLoading(false);
		} else {
			adminApi.postLogin(login, password).then(
				(result: any) => {
					if (result.status === "ok") {
						setItem("token", result.token);
						removeItem("current_project");
						window.location.href = "/";
						setLoginError(false);
						setLoading(false);
					} else {
						setErrorMessage(t(result.message));
						setLoginError(true);
						setLoading(false);
					}
				},
				(error) => {
					console.log(error);
					setLoginError(true);
					setLoading(false);
				}
			);
		}
	};

	/** Placeholders */

	const placeholder = {
		login: t("ACCOUNT.FORM.LABEL.login"),
		password: t("ACCOUNT.FORM.LABEL.password"),
	};

	return (
		<div className="login_container">
			{pageLoading && (
				<div className="loader">
					<CircularProgress />
				</div>
			)}

			<div className="form">
				<div className="content">
					<div className="switch_account_action">
						<strong>Connexion à l'interface d'administration</strong>
					</div>

					<div className="fields">
						<TextField
							value={login}
							placeholder={"Login ou email"}
							onChange={(element: any) => setLogin(element.target.value)}
						/>
					</div>

					<div className="fields">
						<TextField
							value={password}
							placeholder={"Mot de passe"}
							onChange={(element: any) => setPassword(element.target.value)}
							type="password"
							onKeyPress={(e: any) => {
								if (e.keyCode === 13) {
									attemptLogin();
								}
							}}
						/>
					</div>
					<div className="actions">
						<FormControl className="fields" sx={{ width: "80%" }}>
							<Button
								disabled={loading}
								variant="contained"
								onClick={attemptLogin}
							>
								{loading ? (
									<CircularProgress />
								) : (
									<>Connexion</>
								)}
							</Button>
						</FormControl>
						{error && (
							<Alert severity="error" className="error">
								{errorMessage}
							</Alert>
						)}
					</div>
				</div>
			</div>

			<div
				className="presentation"
				style={{
					backgroundImage: `url(${background})`,
				}}
			>
				<img src={logo} className="logo" />
			</div>
		</div>
	);
}

export default Login;
