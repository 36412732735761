import abstractApiObject from "./abstractApiObject";

class moviesApiObject extends abstractApiObject {
	constructor() {
		super();
		this.controller = "film";
	}

}

export default moviesApiObject;
