import React from "react";
import { useNavigate } from "react-router-dom";

import { TableColumnsType } from "Types/Table";
import EditableCell from "./EditableCell";
import CircularProgress from "@mui/material/CircularProgress";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

export const defaultColumns: TableColumnsType[] = [
	{ label: "Identifiant", key: "id", sortable: true, size: 1 },
	{ label: "Nom", key: "name", sortable: true, size: 10 },
	{
		label: "Action",
		key: "",
		sortable: false,
		custom: true,
		render: () => <div></div>,
		size: 4,
	},
];

function Table({
	datas,
	columns,
	loading,
	pagination,
	onSort,
	onPageChange,
	callback,
	onEdit,
}: {
	datas: any[];
	columns: TableColumnsType[];
	loading: boolean;
	pagination?: { page: number; total: number; navigate?: any };
	onSort?: Function;
	callback?: Function;
	onPageChange?: Function;
	onEdit?: Function;
}) {
	const navigate = useNavigate();

	const [sorted, setSorted] = React.useState("");
	const [sortedOrder, setSortedorder] = React.useState("ASC");

	if (pagination) {
		pagination.navigate = navigate;
	}

	return (
		<>
			{loading ? (
				<div className="tableLoader">
					<div className="loader">
						<CircularProgress />
					</div>
				</div>
			) : (
				<div className="tableComponent">
					<div className="table">
						<div className="tableHeader">
							{columns.map((column: TableColumnsType, index: number) => {
								let className = "column";
								if (column.sortable) {
									className += " sortable";
								}
								if (column.center) {
									className += " center";
								}

								return (
									<div
										className={className}
										style={{ width: column.size * 10 + "%" }}
										key={index}
										onClick={() => {
											if (column.sortable) {

												if (sorted === column.key) {
													setSortedorder(
														sortedOrder === "ASC" ? "DESC" : "ASC"
													);
												} else {
													setSorted(column.key);
													setSortedorder("ASC");
												}
												onSort &&
													onSort(
														column.key,
														sorted === column.key
															? sortedOrder === "ASC"
																? "DESC"
																: "ASC"
															: "ASC"
													);
											}
										}}
									>
										{column.label}
										{column.sortable && (
											<div className="sort">
												{sorted === column.key && (
													<>
														{sortedOrder === "ASC" ? (
															<ArrowDropDownIcon />
														) : (
															<ArrowDropUpIcon />
														)}
													</>
												)}
											</div>
										)}
									</div>
								);
							})}
						</div>
						<div className="tableBody">
							{datas.map((item: any, index: number) => {
								return (
									<div className={"row"} key={index}>
										{columns.map((column: TableColumnsType, index: number) => {
											const value = item[column.key];
											return (
												<div
													key={index}
													className={column?.center ? "entry center" : "entry"}
													style={{ width: column.size * 10 + "%" }}
												>
													{column.editable && onEdit ? (
														<EditableCell
															dbkey={column.key}
															type={column?.type ? column.type : "string"}
															value={
																typeof value === "string"
																	? value
																	: value?.toString()
															}
															onChange={onEdit}
															item={item.props}
														/>
													) : (
														<>
															{typeof value === "function" ? (
																<>
																	{value(
																		item,
																		column,
																		pagination,
																		navigate,
																		callback
																	)}
																</>
															) : (
																<span>{value}</span>
															)}
														</>
													)}
												</div>
											);
										})}
									</div>
								);
							})}
						</div>
						<div className="tableFooter">
							{pagination?.page && onPageChange && (
								<div className="pagination">
									<>
										<button
											className={pagination.page === 1 ? "disabled" : ""}
											onClick={() => {
												pagination.page > 1 && onPageChange(1);
											}}
										>
											<KeyboardDoubleArrowLeftIcon />
										</button>
										<button
											className={pagination.page === 1 ? "disabled" : ""}
											onClick={() => {
												pagination.page > 1 &&
													onPageChange(pagination.page - 1);
											}}
										>
											<ChevronLeftIcon />
										</button>

										{pagination.page > 1 && (
											<button onClick={() => onPageChange(1)}>1</button>
										)}
										{pagination.page > 2 && (
											<button onClick={() => onPageChange(2)}>2</button>
										)}

										{pagination.page > 5 && <span>...</span>}

										{pagination.page - 2 >= 3 && (
											<button onClick={() => onPageChange(pagination.page - 2)}>
												{pagination.page - 2}
											</button>
										)}
										{pagination.page - 1 >= 3 && (
											<button onClick={() => onPageChange(pagination.page - 1)}>
												{pagination.page - 1}
											</button>
										)}

										<span className="active">{pagination.page}</span>
										{pagination.page + 1 <= pagination.total - 2 && (
											<button onClick={() => onPageChange(pagination.page + 1)}>
												{pagination.page + 1}
											</button>
										)}
										{pagination.page + 2 <= pagination.total - 2 && (
											<button onClick={() => onPageChange(pagination.page + 2)}>
												{pagination.page + 2}
											</button>
										)}
										{pagination.page <= pagination.total - 5 && (
											<span>...</span>
										)}

										{pagination.page < pagination.total - 1 && (
											<button
												onClick={() => onPageChange(pagination.total - 1)}
											>
												{pagination.total - 1}
											</button>
										)}
										{pagination.page < pagination.total && (
											<button onClick={() => onPageChange(pagination.total)}>
												{pagination.total}
											</button>
										)}
										<button
											className={
												pagination.page === pagination.total ? "disabled" : ""
											}
											onClick={() => {
												pagination.page < pagination.total &&
													onPageChange(pagination.page + 1);
											}}
										>
											<ChevronRightIcon />
										</button>
										<button
											className={
												pagination.page === pagination.total ? "disabled" : ""
											}
											onClick={() => {
												pagination.page < pagination.total &&
													onPageChange(pagination.total);
											}}
										>
											<KeyboardDoubleArrowRightIcon />
										</button>
									</>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default Table;
