/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import LinearProgress, {
	LinearProgressProps,
} from "@mui/material/LinearProgress";
import { CardActionArea } from "@mui/material";
import { Grid, Box } from "@mui/material";
import {
	PieChart,
	Pie,
	Legend,
	Tooltip,
	Cell,
	LabelList,
	ResponsiveContainer,
} from "recharts";

import COLORS from "Utils/colors";

/** Modules  */
import Notifications from "Components/Parts/Notifications";
import AccountIcon from "Components/Parts/Account";
import Logout from "Components/Parts/Logout";

import { StatsType } from "Types/User";
import UserApi from "Api/Models/User";

/** Images */
const icon = require("Assets/Images/clear/navigation/report_icon.png");

function Reports() {
	const { t } = useTranslation();

	/*const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  */

	const statType: StatsType = {
		nb_project: 0,
		nb_event: 0,
		event_by_type: [],
		event_by_size: [],
		used_space: 0,
		total_space: 0,
		used_space_percent: 0,
	};
	const [stats, setStats] = useState(statType);

	useEffect(() => {
		const userApi = new UserApi();
		let eventList: any = [];

		userApi.getStats().then(
			(result: any) => {
				if (result) {
					const newArray = result.data.event_by_type.filter(
						(elmt: any, index: number) => {
							elmt.value = parseInt(elmt.value);
							return elmt;
						}
					);
					result.data.event_by_type = newArray;
					setStats(result.data);
				}
			},
			(error: string) => {
				console.log(error);
			}
		);
	}, []);

	return (
		<div className="Page reports">
			<header>
				<div className="pageName">
					<div className="icon">
						<img src={icon} alt="Reports" />
					</div>
					<strong>{t("REPORTS.title")}</strong>
				</div>
				<Notifications />
			</header>

			<div className="content">
				<Grid container spacing={2}>
					<Grid item md={3}>
						<Card variant="outlined">
							<CardActionArea>
								<CardContent>
									<Typography gutterBottom variant="h5" component="div">
										{t("REPORTS.number_of_projects")}
									</Typography>
									<Typography variant="h2" color="text.secondary">
										{stats.nb_project}
									</Typography>
								</CardContent>
							</CardActionArea>
						</Card>
					</Grid>
					<Grid item md={3}>
						<Card variant="outlined">
							<CardActionArea>
								<CardContent>
									<Typography gutterBottom variant="h5" component="div">
										{t("REPORTS.number_of_events")}
									</Typography>
									<Typography variant="h2" color="text.secondary">
										{stats.nb_event}
									</Typography>
								</CardContent>
							</CardActionArea>
						</Card>
					</Grid>

					<Grid item md={6}>
						<Card variant="outlined">
							<CardActionArea>
								<CardContent>
									<Typography gutterBottom variant="h5" component="div">
										{t("REPORTS.space_used")}
									</Typography>
									<Typography variant="h3" color="text.secondary">
										{stats.used_space} MB / {stats.total_space} MB
									</Typography>

									<Box sx={{ display: "flex", alignItems: "center" }}>
										<Box sx={{ width: "100%", mr: 1 }}>
											<LinearProgress
												value={stats.used_space_percent}
												variant="determinate"
												color="primary"
											/>
										</Box>
										<Box sx={{ minWidth: 35 }}>
											<Typography variant="body2" color="text.secondary">
												{stats.used_space_percent}%
											</Typography>
										</Box>
									</Box>
								</CardContent>
							</CardActionArea>
						</Card>
					</Grid>

					<Grid item md={6}>
						<>
							<Card>
								<CardContent>
									<Typography gutterBottom variant="h5" component="div">
										{t("REPORTS.event_by_type")}
									</Typography>

									<div style={{ width: "100%", height: 400 }}>
										<ResponsiveContainer>
											<PieChart width={600} height={400}>
												<Pie
													data={stats.event_by_type}
													cx="50%"
													cy="50%"
													label
													isAnimationActive={false}
													//labelLine={false}
													//label={renderCustomizedLabel}
													innerRadius={60}
													outerRadius={150}
													fill="#8884d8"
													dataKey="value"
												>
													{stats.event_by_type.map((entry, index) => (
														<Cell
															key={`cell-${index}`}
															fill={COLORS[index % COLORS.length]}
														/>
													))}
												</Pie>
												<Tooltip />
											</PieChart>
										</ResponsiveContainer>
									</div>
								</CardContent>
							</Card>
						</>
					</Grid>
					<Grid item md={6}>
						<Card>
							<CardContent>
								<Typography gutterBottom variant="h5" component="div">
									{t("REPORTS.event_by_size")}
								</Typography>

								<div style={{ width: "100%", height: 400 }}>
									<ResponsiveContainer>
										<PieChart width={600} height={400}>
											<Pie
												data={stats.event_by_size}
												cx="50%"
												cy="50%"
												label
												//labelLine={false}
												//label={renderCustomizedLabel}
												innerRadius={60}
												outerRadius={150}
												dataKey="value"
											>
												<LabelList dataKey="name" position="insideTop" />
												{stats.event_by_size.map((entry, index) => (
													<Cell
														key={`cell-${index}`}
														fill={COLORS[index % COLORS.length]}
													/>
												))}
											</Pie>
										</PieChart>
									</ResponsiveContainer>
								</div>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</div>
		</div>
	);
}

export default Reports;
