import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

import { initialState } from "Types/Event";

export const EventsSlice = createSlice({
	name: "event",
	initialState,
	reducers: {
		loadEvents: (state, action: PayloadAction<any>) => {},
		refreshEvents: (state, action: PayloadAction<any>) => {},
		saveEvent: (state, action: PayloadAction<any>) => {},
		deleteEvent: (state, action: PayloadAction<any>) => {},
		clearEvents: (state, action: PayloadAction<any>) => {
			state.list = initialState.list;
		},
		clearCurrentEvent: (state, action: PayloadAction<any>) => {
			state.current = initialState.current;
		},
		setCurrentEvent: (state, action: PayloadAction<any>) => {
			state.current = action.payload;
		},
		setIsSearch: (state, action: PayloadAction<boolean>) => {
			state.isSearch = action.payload;
		},
		setNeedUpdate: (state, action: PayloadAction<boolean>) => {
			state.needUpdate = action.payload;
		},
		setEvents: (state, action: PayloadAction<any>) => {
			state.list = action.payload;
		},
		setFilters: (state, action: PayloadAction<any>) => {
			state.filters = action.payload;
		},
		setAmounts: (state, action: PayloadAction<any>) => {
			const newAmounts = { ...state.amounts };
			if (action?.payload?.events) {
				newAmounts.events = action?.payload?.events;
			}

			if (action?.payload?.files >= 0) {
				newAmounts.files = action?.payload?.files;
			}

			if (action?.payload?.tasks >= 0) {
				newAmounts.tasks = action?.payload?.tasks;
			}
			state.amounts = newAmounts;
		},
	},
});

export const {
	refreshEvents,
	loadEvents,
	setEvents,
	clearEvents,
	setFilters,
	setAmounts,
	setCurrentEvent,
	clearCurrentEvent,
	saveEvent,
	deleteEvent,
	setIsSearch,
	setNeedUpdate,
} = EventsSlice.actions;
export const getEvents = (state: RootState) => state.event.list;
export const getFilters = (state: RootState) => state.event.filters;
export const isSearch = (state: RootState) => state.event.isSearch;
export const needUpdate = (state: RootState) => state.event.needUpdate;
export const getAmounts = (state: RootState) => state.event.amounts;
export const getCurrentEvent = (state: RootState) => state.event.current;

export default EventsSlice.reducer;
