/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

import {convertStringToDate, formatTimeString} from "Utils/DateFormatter";

function useOutsideAlerter(ref, callback: Function, newValue: any) {
	React.useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				callback(newValue);
			}
		}
		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref, newValue]);
}

function EditableCell(props: {
	type: string;
	value: any;
	dbkey: string;
	onChange: Function;
	item?: any;
}) {
	const {type, value, onChange, dbkey, item} = props;

	const wrapperRef = React.useRef(null);
	const inputRef = React.useRef<HTMLInputElement>(null);

	const [isEditing, setIsEditing] = React.useState(false);
	const [editedValue, setEditedValue] = React.useState(value);

	useOutsideAlerter(
		wrapperRef,
		(value: any) => {
			setIsEditing(false);
			setTimeout(() => {
				handleEndEditing(value);
			}, 100);
		},
		editedValue
	);

	const handleEndEditing = (newValue?: string | boolean) => {
		item[dbkey] = newValue ? newValue : editedValue;
		onChange(item, dbkey);
	};

	React.useEffect(() => {
		if (isEditing && inputRef?.current) {
			inputRef?.current.focus();
		}
	}, [isEditing]);

	return (
		<>
			{isEditing ? (
				<div className="entry_edit" ref={wrapperRef}>
					{(type === "string" || type === "date" || type === "time") && (
						<input
							ref={inputRef}
							type={type === "string" ? "text" : type}
							value={editedValue}
							onChange={(element) => setEditedValue(element.target.value)}
							onKeyUp={(e) => {
								if (e.key === "Enter") {
									handleEndEditing();
									setIsEditing(false);
								}
							}}
						/>
					)}
				</div>
			) : (
				<>
					{type === "boolean" ? (
						<div className={editedValue ? "active flagButton" : " flagButton"}>
							{editedValue === "1" ? (
								<CheckCircleIcon
									onClick={() => {
										setEditedValue("0");
										handleEndEditing("0");
									}}
								/>
							) : (
								<RadioButtonUncheckedIcon
									onClick={() => {
										setEditedValue("1");
										handleEndEditing("1");
									}}
								/>
							)}
						</div>
					) : (
						<div
							className="entry_value"
							onClick={() => {
								setIsEditing(true);
							}}
						>
							<span>
								{type === "date" && convertStringToDate(editedValue)}
								{type === "time" && formatTimeString(editedValue)}
								{type === "string" && editedValue}
							</span>
						</div>
					)}
				</>
			)}
		</>
	);
}

export default EditableCell;
