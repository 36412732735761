/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import CircularProgress from "@mui/material/CircularProgress";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";

import {
	DragDropContext,
	Droppable,
	Draggable,
	resetServerContext,
} from "react-beautiful-dnd";

import Medias from "Components/Parts/Medias";

import Details from "./Modal_details";

import {
	getScreen,
	updatePictures,
	editPicture,
	deletePicture,
} from "Api/Controllers/ScreensController";

resetServerContext();

function Screen_edit() {
	const navigate = useNavigate();
	const { id } = useParams();
	const placeId = id ? parseInt(id) : 0;

	const [loading, setLoading] = useState(true);
	const [screen, setScreen] = useState<any>({});

	const [fileList, setFileList] = useState<any[]>([]);
	const [itemList, setItemList] = useState(Object.keys([]));

	const [modified, setModified] = useState(false);
	const [needRefresh, setNeedRefresh] = useState(false);

	const [loaded, setLoaded] = useState(false);

	const [openImageDetails, setOpenImageDetails] = useState(false);
	const [imageDetails, setImageDetails] = useState<any>({});
	const [imageLoading, setImageLoading] = useState(-1);

	const [lightboxImage, setLightboxImage] = React.useState<any>({});
	const [showLightbox, setShowLightbox] = React.useState(false);

	/** Drag & drop */

	// Function to update list on drop

	const handleDrop = (droppedItem: any) => {
		// Ignore drop outside droppable container
		if (!droppedItem.destination) return;
		var updatedList = [...itemList];
		// Remove dragged item
		const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
		// Add dropped item
		updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
		// Update State

		setItemList(updatedList);
	};

	const deletePictureItem = (id: any) => {
		const itemToDelete = fileList[id];
		const newList = itemList.filter((item) => item !== id);
		setItemList(newList);
		deletePicture(itemToDelete.id).then(() => { });
	};

	const saveScreen = () => {
		setLoading(true);
		const picturesToSave: any = [];

		itemList.forEach((key: string) => {
			const picture = fileList[parseInt(key)];

			const datas: any = {
				...picture,
				position: picturesToSave.length,
			};

			picturesToSave.push(datas);
		});

		updatePictures({ id_screen: screen.id, pictures: picturesToSave }).then(
			() => {
				setLoading(false);
			}
		);
		resetServerContext();
	};

	const saveImageDetails = (image: any) => {
		const updatedFilelist = [...fileList];

		const index = updatedFilelist.findIndex(
			(element: any) => element.id === image.id
		);

		const updatedImage = { ...image };
		updatedImage.path = updatedFilelist[index].path;
		updatedImage.name = updatedFilelist[index].name;
		updatedImage.upload_time = updatedFilelist[index].upload_time;

		updatedFilelist[index] = updatedImage;
		setFileList(updatedFilelist);
		setImageLoading(image.id);
		setOpenImageDetails(false);

		delete image.path;
		delete image.upload_time;
		delete image.name;

		editPicture(image).then(
			(response: any) => {
				setImageLoading(-1);
			},
			(error) => {
				setImageLoading(-1);
			}
		);
	};

	const openLightbox = (image: any) => {
		setLightboxImage(image);
		setShowLightbox(true);
	};

	useEffect(() => {
		getScreen(placeId).then((response: any) => {
			setScreen(response.infos);

			setFileList(response.pictures);
			setItemList(Object.keys(response.pictures));
			setLoading(false);
		});
	}, [needRefresh]);

	useEffect(() => {
		if (loaded) {
			setModified(true);
		} else {
			setLoaded(true);
		}
	}, [itemList]);

	return (
		<div className="Page dashboard">
			<Details
				image={imageDetails}
				onClose={(details: any) => saveImageDetails(details)}
				open={openImageDetails}
			/>
			<LightBox
				image={lightboxImage}
				open={showLightbox}
				onClose={() => setShowLightbox(false)}
			/>

			<header>
				<div className="pageName">
					<Button onClick={() => navigate("/ecrans")}>
						<KeyboardBackspaceIcon />
					</Button>
					<strong>{screen.name}</strong>
				</div>
				<a href={/show/ + screen.id} target="_blank" className="link_screen">
					<span>Afficher l'écran</span>
				</a>
				<LoadingButton
					className="saveButton"
					onClick={saveScreen}
					loading={loading}
					disabled={!modified}
					variant="contained"
					endIcon={<SaveIcon />}
				>
					Enregistrer
				</LoadingButton>
			</header>

			<div className="content">
			<strong>Galerie de l'écran</strong>
				<div className="row screen_pictures">
					{fileList.length > 0 ? (
						<>
							<ul className="screens_file_list">
								<DragDropContext onDragEnd={handleDrop}>
									<Droppable droppableId="list-container">
										{(provided) => (
											<div
												className="list-container"
												{...provided.droppableProps}
												ref={provided.innerRef}
											>
												{itemList.map((item, index) => {
													return (
														<Draggable
															key={item}
															draggableId={item}
															index={index}
														>
															{(provided) => {
																const picture = fileList[parseInt(item)];
																return (
																	<div
																		ref={provided.innerRef}
																		className="item-container"
																		{...provided.dragHandleProps}
																		{...provided.draggableProps}
																	>
																		{picture.path && (
																			<li className="item-element">
																				<div>
																					<DragHandleIcon />
																					<span className="position">
																						{index + 1}
																					</span>
																					<img
																						src={picture.path}
																						alt={picture.name}
																						onClick={() => {
																							setImageDetails(picture);
																							setTimeout(() => {
																								setOpenImageDetails(true);
																							}, 10);
																						}}
																					/>
																					<span
																						className="picture_name"
																						onClick={() => {
																							setImageDetails(picture);
																							setTimeout(() => {
																								setOpenImageDetails(true);
																							}, 10);
																						}}
																					>
																						{picture.name.length > 80
																							? picture.name.substring(
																								0,
																								80
																							) + "..."
																							: picture.name}
																					</span>
																					<div className="action">
																						<button
																							className="edit_button"
																							onClick={() => {
																								setImageDetails(picture);
																								setTimeout(() => {
																									setOpenImageDetails(true);
																								}, 10);
																							}}
																						>
																							{imageLoading === picture.id ? (
																								<CircularProgress size={18} />
																							) : (
																								<EditIcon />
																							)}
																						</button>

																						<button
																							onClick={() =>
																								deletePictureItem(item)
																							}
																						>
																							<ClearIcon />
																						</button>
																					</div>
																				</div>
																			</li>
																		)}
																	</div>
																);
															}}
														</Draggable>
													);
												})}
											</div>
										)}
									</Droppable>
								</DragDropContext>
							</ul>
						</>
					) : (
						<span>Aucun fichier envoyé</span>
					)}
				</div>
			</div>
		</div>
	);
}

function LightBox(props: { image: any; open: Boolean; onClose: Function }) {
	const [picture, setPicture] = React.useState<string>("");
	const [show, setShow] = React.useState<boolean>(false);

	React.useEffect(() => {
		props.open ? setShow(true) : setShow(false);
	}, [props.open]);

	React.useEffect(() => {
		setPicture(props.image?.path);
	}, [props.image]);

	const closeLightbox = () => {
		setShow(false);
		props.onClose();
	};

	return (
		<div
			className={show ? "open lightbox" : "lightbox"}
			onClick={closeLightbox}
		>
			<img src={picture} alt={props.image?.name} />
		</div>
	);
}

export default Screen_edit;
