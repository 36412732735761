const RenderActions = (data: any) => {
	return <div></div>;
};

const columns = [
	{
		key: "status",
		label: "Statut",
		sortable: true,
		size: 3,
	},
	{
		key: "name",
		label: "Nom",
		sortable: true,
		show: true,
		min: true,
		size: 6,
	},
	{
		key: "cinema",
		label: "Cinéma",
		sortable: true,
		size: 3,
	},
	
	{
		key: "action",
		label: "Actions",
		sortable: false,
		custom: true,
		render: RenderActions,
		size: 2,
	},
];

export default columns;
