import React from "react";
import {useTranslation} from "react-i18next";

import TextField from "@mui/material/TextField";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

function Search({
	label,
	value,
	onChange,
}: {
	label: string;
	value: string;
	onChange: Function;
}) {
	const [currentValue, setCurrentValue] = React.useState(value);

	React.useEffect(() => {
		setCurrentValue(value);
	}, [value]);
	return (
		<div className="searchField">
			<TextField
				className="searchFieldInput"
				label={label}
				size="small"
				variant="outlined"
				value={currentValue}
				onChange={(e) => setCurrentValue(e.target.value)}
				onKeyDown={(e) => {
					e.key === "Enter" && onChange(currentValue);
				}}
			/>
			<span
				className={
					value && value !== "" ? "searchKeyInfo" : "searchKeyInfo empty"
				}
			>
				Appuyer sur la touche <KeyboardReturnIcon /> pour lancer la recherche
			</span>
		</div>
	);
}

export default Search;
