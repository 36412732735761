/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {useTranslation} from "react-i18next";
import Select from "react-select";

import {setItem} from "Utils/Storage";
import COLORS from "Utils/colors";

import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

import Switch from "@mui/material/Switch";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

/** Modules  */
import Notifications from "Components/Parts/Notifications";
import AccountIcon from "Components/Parts/Account";
import Logout from "Components/Parts/Logout";

/** Redux */

import {useAppDispatch, useAppSelector} from "Redux/hooks";
import {getUser, setUser} from "Redux/Reducers/User/UserSlice";

/** Images */
const settingsIcon = require("Assets/Images/clear/navigation/settings_icon.png");

function Settings() {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const user = useAppSelector(getUser);

	const [acceptNotification, setAcceptNotifications] = React.useState({
		general: true,
		project: false,
	});
	const [loading, setLoading] = React.useState(false);

	const saveParameters = () => {
		setLoading(true);

		const newUser = structuredClone(user);
		dispatch(setUser(newUser));

		setTimeout(() => {
			setLoading(false);
			window.location.reload();
		}, 1000);
	};

	React.useEffect(() => {
		let currentLang = user?.settings?.language;
		if (!currentLang) currentLang = localStorage.getItem("language");
	}, [user]);

	return (
		<div className="Page settings">
			<header>
				<div className="pageName">
					<strong>Paramètres</strong>
				</div>
			</header>

			<div className="content">
				<div className="tabContent row">
					<div className="col"></div>
				</div>
			</div>
		</div>
	);
}

export default Settings;
