/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from "react";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

/** Modules  */
import Search from "Components/Parts/Search";
import columnsDefinition from "./Columns";

/** Components */

import Table from "Components/Parts/Table";
import FancyBox from "Components/Parts/Fancybox";
/** API */

import {getMovies} from "Api/Controllers/MoviesController";

function RenderFlag(item: {props: any}, column: {key: string; label: string}) {
	const {key, label} = column;
	const film = item.props;

	return (
		<div
			className={
				key && parseInt(film[key]) === 1 ? "active flagButton" : " flagButton"
			}
		>
			{key && parseInt(film[key]) === 1 ? (
				<CheckCircleIcon />
			) : (
				<RadioButtonUncheckedIcon />
			)}
		</div>
	);
}

function RenderTitle(
	item: {props: any},
	column: {key: string; label: string},
	pagination: any,
	navigate: any,
	callback: Function
) {
	const film = item.props;

	return (
		<div className={"tableTitle"}>

			<a data-fancybox="gallery" href={`https://www.grignoux.be//upload/grignoux/films/${film.id}/${film.cover}`}>
				<img
					src={`https://www.grignoux.be//upload/grignoux/films/${film.id}/thumbs/${film.cover}`}
					alt={film.title}
				/>
			</a>

			
			<div className="movieInfos">
				<strong className="movieTitle">
					{film.title} {film.year}
				</strong>
				<span className="movieTitleOriginal">{film.title_original}</span>
				<a
					className="producer"
					onClick={() => {
						callback(film.realisateur);
					}}
				>
					{film.realisateur}
				</a>
				<span className="actors">{film.actors}</span>
			</div>
		</div>
	);
}

function RenderAction(props: any) {
	return <div></div>;
}

function Movies() {
	const [loading, setLoading] = useState(true);

	const [moviesList, setMoviesList] = useState([] as any[]);
	const [searchedValue, setSearchedValue] = useState("");
	const [filters, setFilters] = useState({
		search: searchedValue,
		page: 1,
		sort: "id",
		sort_direction: "ASC",
	});
	const [columns, setColumns] = useState(columnsDefinition);

	const [pagination, setPagination] = useState({} as any);

	useEffect(() => {
		setColumns(columnsDefinition);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getMoviesList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	useEffect(() => {
		setSearchedValue(filters.search);
	}, [filters.search]);

	const getMoviesList = async (orderby = "id", order = "ASC") => {
		setLoading(true);
		const response: any = await getMovies(filters);

		const datas: any = [];
		setLoading(false);

		const currentPagination = {
			page: response.data.currentpage,
			total: response.data.nbpage,
		};

		setPagination(currentPagination);

		if (response.data.films.length > 0) {
			for (const film of response.data.films) {
				datas.push({
					id: film.id,
					id_gticket: film.id_ext,
					title: RenderTitle,
					alaune: RenderFlag,
					archived: RenderFlag,
					action: RenderAction,
					year: film.year,
					date_sortie: film.date_sortie,
					soon: RenderFlag,
					eltn: RenderFlag,
					published: RenderFlag,
					props: film,
				});
			}
			setMoviesList(datas);
		}
	};

	return (
		<div className="Page listing">
		
			<header>
				<div className="pageName">
					<strong>Liste des films</strong>
				</div>
				<Search
					label="Rechercher un film"
					value={searchedValue}
					onChange={(value: string) => {
						setSearchedValue(value);
						setFilters({...filters, search: value, page: 1});
					}}
				/>
			</header>
			
			<div className="content fancyboxContainer">
			<FancyBox>
			<div className="row">
					<Table
						datas={moviesList}
						columns={columns}
						loading={loading}
						pagination={pagination}
						onPageChange={(page: number) => {
							const newFilters = {
								...filters,
								page: page,
							};
							setFilters(newFilters);
						}}
						onSort={(column: string, order: string) => {
							const newFilters = {
								...filters,
								sort: column,
								sort_direction: order,
							};
							setFilters(newFilters);
						}}
						callback={(search: string) => {
							setFilters({...filters, search: search, page: 1});
						}}
					/>
				</div>
				</FancyBox>
				
			</div>
		
		</div>
	);
}

export default Movies;
