import React, { useState, useEffect } from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
function Notifications() {
  const [notifications, setNotifications] = useState(0);

  useEffect(() => {
    setNotifications(0);
  }, []);
  return (
    <div className={"notifications"}>
      <NotificationsIcon />
      {notifications > 0 && (
        <span className={notifications < 100 ? "normal" : "min"}>
          {notifications}
        </span>
      )}
    </div>
  );
}

export default Notifications;
